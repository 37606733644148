import { createFeatureSelector, createSelector } from '@ngrx/store';

import { globalReducerInterface } from './global';
import { globalStateKey } from './global.reducer';

export const globalState =
  createFeatureSelector<globalReducerInterface>(globalStateKey);

export const showMenuFeature = createSelector(
  globalState,
  (state) => state.menu.showMenu
);

export const getNotifications = createSelector(globalState, (state) => {
  return state.notifications;
});

export const selectFleetOwnerIDFeature = createSelector(
  globalState,
  (data) => data.fleetOwnerID
);
