import { Injectable } from '@angular/core';
import { CountsService } from '@backoffice/ng-backoffice-api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import { catchError, concat, map, of, switchMap, tap } from 'rxjs';

import { resourcesWidgetLocalStorageKey } from '../../../constants';

import { LocalService } from '../../../services/local-storage.service';

import { withFleetOwnerID } from '../../../store/global.helper';

import { resourcesAPIActions } from './resources.actions';

@Injectable()
export class ResourcesEffects {
  constructor(
    private actions$: Actions,
    private localService: LocalService,
    private countsService: CountsService,
    private store: Store
  ) {}

  readonly getResourcesCounters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resourcesAPIActions.resourcesCountersLoading),
      switchMap(() => {
        const localData = this.localService.get(resourcesWidgetLocalStorageKey);

        // if we have the data in the local storage, we use it
        const localData$ = localData
          ? of({
              type: resourcesAPIActions.resourcesCountersLoaded.type,
              resourcesCounters: {
                loaded: true,
                ...JSON.parse(localData),
              },
            })
          : of();

        const serverData$ = withFleetOwnerID(this.store, (fleetOwnerID) => {
          return this.countsService
            .countsControllerGetResourcesCounts(fleetOwnerID)
            .pipe(
              // storing the data in the local storage
              tap((counters) => {
                this.localService.set(
                  resourcesWidgetLocalStorageKey,
                  JSON.stringify(counters)
                );
              }),
              map((counters) => ({
                type: resourcesAPIActions.resourcesCountersLoaded.type,
                resourcesCounters: {
                  loaded: true,
                  ...counters,
                },
              })),
              catchError((error) =>
                of({
                  type: resourcesAPIActions.resourcesCountersError.type,
                  error: {
                    message: 'Error loading counter',
                    error,
                  },
                })
              )
            );
        });

        // Concat the two observables
        // first emit the local data (if any), then make the backend call
        return concat(localData$, serverData$);
      })
    )
  );
}
