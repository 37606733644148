import { ConformityView, UserView } from '@backoffice/ng-backoffice-api-client';
import { DateUtil } from '@common/utils';
import { TranslateService } from '@ngx-translate/core';

import { userTableInterface } from '../users';

export const handleUserDataTable = (
  user: UserView,
  translateService: TranslateService
): userTableInterface => {
  const {
    id,
    firstName,
    lastName,
    email,
    isELDManager,
    updatedDate,
    isConform,
  } = user;
  return {
    id: [id, { notShow: false }],
    name: `${firstName} ${lastName}`,
    email: email,
    isELDManager: handleIsELDManager(isELDManager, isConform, translateService),
    updatedDate: [
      new DateUtil().fromISOToShortDateWithTime24(updatedDate),
      {
        dataType: 'DATE',
        data: updatedDate,
      },
    ],
    conformity: [
      isELDManager
        ? handleUserConformity(isConform, translateService)
        : translateService.instant('NOT_APPLICABLE'),
      {
        dataType: 'CELL_VALIDITY',
        data:
          !isELDManager ||
          (isELDManager &&
            Object.values(isConform).every(
              (conformityRule) => !conformityRule
            )),
      },
    ],
    validRow:
      !isELDManager ||
      (isELDManager &&
        Object.values(isConform).every((conformityRule) => !conformityRule)),
  };
};

const handleIsELDManager = (
  isELDManager: boolean,
  isConform: ConformityView,
  translateService: TranslateService
): string => {
  const isELDManagerTranslated = isELDManager
    ? translateService.instant('YES')
    : translateService.instant('NO');
  const isConformTranslated =
    isELDManager && Object.values(isConform).some((value) => value)
      ? ` (${translateService.instant('USERS.NOT_CONFORM')})`
      : '';
  return `${isELDManagerTranslated}${isConformTranslated}`;
};

const handleUserConformity = (
  conformityView: ConformityView,
  translateService: TranslateService
) => {
  const isNotConform = Object.values(conformityView).some((value) => value);
  return isNotConform
    ? translateService.instant('NO')
    : translateService.instant('YES');
};
