import { createFeatureSelector, createSelector } from '@ngrx/store';

import { resourcesReducerInterface } from './resources';
import { resourcesFeatureKey } from './resources.reducer';

export const resourcesFeature =
  createFeatureSelector<resourcesReducerInterface>(resourcesFeatureKey);

export const selectInfoboxDataFeature = createSelector(
  resourcesFeature,
  (data) => data.infoboxData
);

export const selectCounterFeature = createSelector(
  resourcesFeature,
  (data) => data.resourcesCounters
);

export const selectInfoboxHeaderFeature = createSelector(
  resourcesFeature,
  (data) => data.infoboxHeader
);

export const selectFleetOwnerIDFeature = createSelector(
  resourcesFeature,
  (data) => data.fleetOwnerID
);
