import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'astus-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  images = [
    '../../../../../assets/images/dog_1.svg',
    '../../../../../assets/images/plug.svg',
  ];

  showMenu = false;

  displayMenu(showMenu: boolean) {
    this.showMenu = showMenu;
  }

  backgroundImage = '';

  constructor(
    private auth: AuthService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit() {
    const index = Math.floor(Math.random() * this.images.length);
    this.backgroundImage = this.images[index];
  }

  logout() {
    this.auth.logout({
      logoutParams: {
        returnTo: this.doc.location.origin,
      },
    });
  }
}
