import {
  ConflictedDriverView,
  DriverView,
  UpdateDriverDto,
} from '@backoffice/ng-backoffice-api-client';

import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { resourcesOpenedEntryType } from '../../store';
import { driverTableInterface } from '../drivers';

import {
  driversFormStatusType,
  driversFormValidationType,
  driversReducerErrorType,
} from './drivers';

export const driversAPIActions = createActionGroup({
  source: 'Drivers API',
  events: {
    'Drivers set current ID': props<{
      driversOpenedEntry: resourcesOpenedEntryType;
    }>(),
    'Drivers unset current ID': emptyProps(),
    'Driver loading': props<{ id: number }>(),
    'Driver loaded': props<{ conflictedDriverView: ConflictedDriverView }>(),
    'Drivers loading': emptyProps(),
    'Drivers loaded': props<{
      driversList: DriverView[];
      driversTableData: driverTableInterface[];
    }>(),
    'Drivers form create': props<{ driversFormCreate: boolean }>(),
    'Drivers add': props<{ driver: UpdateDriverDto }>(),
    'Drivers add success': props<{
      driver: DriverView;
      driverTable: driverTableInterface;
    }>(),
    'Drivers update': props<{ id: number; driver: UpdateDriverDto }>(),
    'Drivers update success': props<{
      driver: DriverView;
      driverTable: driverTableInterface;
      index: number;
    }>(),
    'Drivers form status': props<{
      driversFormStatus: driversFormStatusType;
    }>(),
    'Drivers form validation': props<{
      validation: driversFormValidationType;
    }>(),
    'Drivers error': props<driversReducerErrorType>(),
    'Drivers error clear': emptyProps(),
    'Drivers unset infobox edition values': emptyProps(),
  },
});
