/**
 * Back-office API
 * In charge of providing data to the back-office frontend apps
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface EldUnidentifiedView {
  vehicleId: number;
  vehicleName: string;
  date: string;
  eventType: EldUnidentifiedView.EventTypeEnum;
  coordinates: string;
  odometer: number | null;
  geoLocation: string | null;
}
export namespace EldUnidentifiedView {
  export type EventTypeEnum = 'ON_DUTY' | 'DRIVING';
  export const EventTypeEnum = {
    OnDuty: 'ON_DUTY' as EventTypeEnum,
    Driving: 'DRIVING' as EventTypeEnum,
  };
}
