import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { DropdownItemInterface, DropdownIdType } from './dropdown';

@Component({
  selector: 'ad-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownOrganism,
    },
  ],
})
export class DropdownOrganism
  implements ControlValueAccessor, OnInit, OnChanges
{
  itemsDropdownIsShown = false;

  @Input()
  label = '';

  @Input()
  value: DropdownIdType = null;

  @Input()
  items: DropdownItemInterface[] = [];

  @Input()
  itemsSecondList: DropdownItemInterface[] = [];

  @Input()
  placeholder: string | null = null;

  @Input()
  errorMessage: string | null = null;

  @Input()
  error = false;

  @Input()
  disabled = false;

  @Input()
  required = false;

  @Input()
  allowEmptyOption = false;

  @Output()
  dropdownElementChange: EventEmitter<DropdownItemInterface | null> =
    new EventEmitter();

  selectedElement: DropdownItemInterface | null = null;

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line
  onChange = (dropdownValue: string | number) => {};

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  touchedValue = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.manualSelectedItemChange(this.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this.manualSelectedItemChange(changes['value'] as unknown as string);
    }

    if (changes['items']) {
      this.manualSelectedItemChange(this.value);
    }
  }

  /**
   * This method is handled the click on the dropdown button.
   */
  onDropdownClick() {
    this.itemsDropdownIsShown = !this.itemsDropdownIsShown;
  }

  /**
   * This method is handled the click on the dropdown item.
   * @param {DropdownItemInterface} item The dropdown item.
   */
  onMenuItemClick(item: DropdownItemInterface) {
    this.selectedElement = item;
    this.onChange(item.value);
    this.markAsTouched();
    this.dropdownElementChange.emit(item);
    this.closeDropdown();
  }

  onMenuEmptyItemClick() {
    this.selectedElement = null;
    this.onChange('');
    this.markAsTouched();
    this.dropdownElementChange.emit(null);
    this.closeDropdown();
  }

  manualSelectedItemChange(id: DropdownIdType) {
    if (id !== null && id !== undefined && id !== '' && !Number.isNaN(id)) {
      // check if the id is in the first list or in the second list
      const isInFirstList = this.items.find(
        (item) => item.value.toString() === id.toString()
      );
      const isInSecondList = this.itemsSecondList.find(
        (item) => item.value.toString() === id.toString()
      );

      if (isInFirstList) {
        this.selectedElement = this.items.filter(
          (item) => item.value.toString() === id.toString()
        )[0];
      }

      if (isInSecondList) {
        this.selectedElement = this.itemsSecondList.filter(
          (item) => item.value.toString() === id.toString()
        )[0];
      }
    } else {
      this.selectedElement = null;
    }
  }

  /**
   * This method is handled the click on the dropdown item.
   */
  closeDropdown() {
    this.itemsDropdownIsShown = false;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeDropdown();
    }
  }

  writeValue(currValue: string) {
    this.value = currValue;
    this.manualSelectedItemChange(this.value);
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touchedValue) {
      this.onTouched();
      this.touchedValue = true;
    }
  }

  setDisabledState(disabledValue: boolean) {
    this.disabled = disabledValue;
  }
}
