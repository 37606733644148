import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import classNames from 'classnames';

export type errorType = { [key: string]: string };

@Component({
  selector: 'ad-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputMolecule,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputMolecule implements ControlValueAccessor, OnChanges {
  objectKeys = Object.keys;

  isMobile = window.innerWidth < 600;

  @Input()
  label = '';

  @Input()
  type:
    | 'text'
    | 'tel'
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'url'
    | 'number'
    | 'textarea' = 'text';

  @Input()
  name = '';

  @Input()
  value: string | null = '';

  @Input()
  placeholder = '';

  // This here accepts an object of errors to be able to show different or multiple messages
  // Reactive form errors can be passes like so :
  // [errors]="testForm.controls['testInput'].errors"
  @Input()
  errors: errorType | ValidationErrors | null = null;

  // The messages are also an object and the keys need to match those of "errors"
  @Input()
  errorMessage: errorType | null = null;

  @Input()
  validated = false;

  @Input()
  disabled = false;

  @Input()
  required = false;

  @Input()
  maxLength: number | null = null;

  @Input()
  copyToClipboard = false;

  @Output() copyToClipboardEvent: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('leadingContent', { static: false })
  leadingContent!: ElementRef<HTMLElement>;

  @ViewChild('trailingContent', { static: false })
  trailingContent!: ElementRef<HTMLElement>;

  @HostBinding('class') class = '';

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line
  onChange = (inputValue: string) => {};

  // Had put a default empty function here, it was causing a lint error
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  touchedValue = false;

  constructor(private ref: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.class = classNames(
      this.errors ? 'has-error' : '',
      this.validated ? 'is-validated' : '',
      this.disabled ? 'is-disabled' : ''
    );

    this.ref.detectChanges();
  }

  /**
   * This function is called when the user changes the value of the input.
   * It emits the new value of the input.
   * @param event The change event.
   */
  onInputChange(event: Event): void {
    this.value = (event.target as HTMLInputElement).value;
    this.onChange(this.value);
    this.markAsTouched();
    this.ref.detectChanges();
  }

  onCopyToClipboard(): void {
    if (this.value) {
      navigator.clipboard.writeText(this.value);
      this.copyToClipboardEvent.emit(true);
    }
  }

  writeValue(currValue: string) {
    this.value = currValue;
    this.ref.detectChanges();
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  // I need to use "any" here because the Angular function literally uses "any" as the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touchedValue) {
      this.onTouched();
      this.touchedValue = true;
    }
    this.ref.detectChanges();
  }

  setDisabledState(disabledValue: boolean) {
    this.disabled = disabledValue;
    this.ref.detectChanges();
  }
}
