<div class="container" [ngClass]="{ 'is-open': showMenu }">
  <div class="main-content">
    <ad-drawer>
      <ad-drawer-item
        *ngIf="shouldDisplayAstusWeb"
        (click)="toggleNotificationCenter()"
        [highlighted]="false"
        label="{{ 'NOTIFICATION_CENTER.TITLE' | translate }}"
        icon="notification"
        notificationType="digit"
        [notificationCount]="notificationCount"
      ></ad-drawer-item>

      <ad-drawer-item
        (click)="closeMenu()"
        routerLink="/astus-pages-content/resources"
        routerLinkActive="is-active"
        [highlighted]="false"
        label="{{ 'MENU.RESOURCES' | translate }}"
        icon="resources"
      ></ad-drawer-item>

      <ad-drawer-item
        (click)="closeMenu()"
        routerLink="/astus-pages-content/reports"
        routerLinkActive="is-active"
        [highlighted]="false"
        label="{{ 'MENU.REPORTS' | translate }}"
        icon="analytics"
      ></ad-drawer-item>

      <ad-separator></ad-separator>

      <ad-drawer-item
        *ngIf="shouldDisplayAstusWeb"
        (click)="closeMenu()"
        routLink="/astus-legacy"
        routerLinkActive="is-active"
        [highlighted]="false"
        label="{{ 'MENU.ASTUS_LEGACY' | translate }}"
        icon="open_new"
      ></ad-drawer-item>

      <ad-drawer-item
        (click)="handleLogout()"
        routLink="/logout"
        routerLinkActive="is-active"
        [highlighted]="false"
        label="{{ 'MENU.LOGOUT' | translate }}"
        icon="logout"
      ></ad-drawer-item>
    </ad-drawer>
    <ad-drawer-panel [isOpen]="showNotificationCenter" [navOpen]="showMenu">
      <ad-drawer
        headerTitle="{{ 'NOTIFICATION_CENTER.TITLE' | translate }}"
        headerGlyph="chevron_left"
        (headerIconClicked)="toggleNotificationCenter()"
        class="notification-center"
      >
        <astus-notification-center
          [notificationData]="this.notifications"
          [notificationCount]="this.notificationCount"
          (readAllNotifications)="readAllNotifications()"
        ></astus-notification-center>
      </ad-drawer>
    </ad-drawer-panel>
  </div>
</div>
