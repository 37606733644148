import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IconAtomGlyphType } from '../../atoms';
import { colors } from '../../common-ng-design-system.colors';

@Component({
  selector: 'ad-drawer-item',
  templateUrl: './navigation-drawer-item.component.html',
  styleUrls: ['./navigation-drawer-item.component.scss'],
})
export class NavigationDrawerItemMolecule {
  @Input() highlighted = false;

  @Input() icon: IconAtomGlyphType | undefined;

  @Input() label = 'Label';

  @Input() notificationType: 'no-digit' | 'digit' | undefined;

  @Input() notificationCount: number | undefined;

  @Input() disabled = false;

  @Output() itemClicked = new EventEmitter<Event>();

  isButtonActive = false;

  onMouseEvent($event: Event) {
    this.isButtonActive = $event.type === 'mouseover';
  }

  onItemClick($event: Event): void {
    this.isButtonActive = true;
    this.itemClicked.emit($event);
  }

  /**
   *
   * this method is used to handle the higlighted and disabled state of the drawer item
   * to pass the right @input color to the badge component and the icon component to change ccs variables
   * in the component
   * @returns keyof typeof colors
   */
  getColor(): keyof typeof colors {
    if (this.disabled) {
      return 'neutral-300';
    }
    return this.isButtonActive || this.highlighted
      ? 'primary-400'
      : 'neutral-700';
  }
}
