import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[adStickyColumns]',
})
export class StickyColumnsDirective implements AfterViewInit, OnChanges {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(): void {
    this.calculateStickyColumns();
  }

  @Input()
  stickyColumnsCount = 0;
  @Input()
  adIsSticky = false;

  @Input()
  applySticky = false;

  @Input()
  tableReady = false;

  _tableReady = false;

  @Output() loadEnd = new EventEmitter<boolean>();

  calculateStickyColumns() {
    if (this.tableReady && !this._tableReady) {
      setTimeout(() => {
        this._tableReady = true;
        this.loadEnd.emit(true);
      }, 0);
      return;
    } else if (!(this.adIsSticky && this.applySticky)) {
      return;
    }

    if (this.adIsSticky && this.applySticky) {
      const el = this.el.nativeElement as HTMLElement;

      let offSet = 0;
      const stickyCells = Array.from(el?.children ?? []);
      const maxZIndex =
        stickyCells.filter((child) => child.classList.contains('pinned'))
          .length + 1;
      let count = 0;
      stickyCells.forEach((cell, index) => {
        if (cell.classList.contains('pinned')) {
          ++count;
          this.renderer.setStyle(cell, 'position', 'sticky');
          this.renderer.setStyle(cell, 'left', `${offSet}px`);
          this.renderer.setStyle(cell, 'z-index', maxZIndex - index);
          offSet += cell.getBoundingClientRect().width;
          if (count === this.stickyColumnsCount) {
            this.renderer.setStyle(cell, 'border-right', '2px solid white');
          }
        } else {
          this.renderer.setStyle(cell, 'position', 'static');
          this.renderer.setStyle(cell, 'left', 'auto');
          this.renderer.setStyle(cell, 'z-index', 'auto');
        }
      });
    }
  }
  ngAfterViewInit() {
    this.calculateStickyColumns();
  }
}
