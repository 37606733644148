import { createReducer, on } from '@ngrx/store';

import { vehiclesReducerInterface } from './vehicles';
import { vehiclesAPIActions } from './vehicles.actions';

export const vehiclesFeatureKey = 'vehiclesFeature';

export const vehiclesInitialState: vehiclesReducerInterface = {
  loaded: false,
  vehiclesList: [],
  vehiclesTableData: [],
  vehiclesOpenedEntry: null,
  error: null,
};

export const vehiclesFeatureReducer = createReducer<vehiclesReducerInterface>(
  vehiclesInitialState,
  on(
    vehiclesAPIActions.vehiclesLoaded,
    (_state, { vehiclesList, vehiclesTableData }) => ({
      ..._state,
      loaded: true,
      vehiclesList,
      vehiclesTableData,
    })
  ),
  on(vehiclesAPIActions.vehiclesError, (_state, { message, error }) => ({
    ..._state,
    error: {
      error,
      message,
    },
  })),
  on(
    vehiclesAPIActions.vehiclesSetCurrentID,
    (_state, { vehiclesOpenedEntry }) => ({
      ..._state,
      vehiclesOpenedEntry,
    })
  ),
  on(vehiclesAPIActions.vehiclesUnsetCurrentID, (_state) => ({
    ..._state,
    vehiclesOpenedEntry: null,
  }))
);
