import {
  ConflictedUserView,
  CreateUserView,
  UpdateUserView,
  UserView,
} from '@backoffice/ng-backoffice-api-client';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { resourcesOpenedEntryType } from '../../store';
import { userTableInterface } from '../users';

import {
  usersFormStatusType,
  usersFormValidationType,
  usersReducerErrorType,
} from './users';

export const usersAPIActions = createActionGroup({
  source: 'Users API',
  events: {
    'Create user': props<{ user: CreateUserView }>(),
    'Update user': props<{
      id: number;
      user: Partial<UpdateUserView>;
      userFullName: Omit<UpdateUserView, 'isELDManager'>;
    }>(),
    'Set user update ID': props<{ id: number }>(),
    'User loading': props<{ id: number }>(),
    'User loaded': props<{ conflictedUserView: ConflictedUserView }>(),
    'Users loading': emptyProps(),
    'Users loaded': props<{
      usersList: UserView[];
      usersTableData: userTableInterface[];
    }>(),
    'Users set current ID': props<{
      usersOpenedEntry: resourcesOpenedEntryType;
    }>(),
    'Users unset current ID': emptyProps(),
    'Users error': props<usersReducerErrorType>(),
    'Users error clear': emptyProps(),
    'Users form create': props<{ usersFormCreate: boolean }>(),
    'Users add': props<{ user: UserView }>(),
    'Users add success': props<{
      user: UserView;
      userTable: userTableInterface;
    }>(),
    'Users update': props<{ id: number; user: UserView }>(),
    'Users update success': props<{
      user: UserView;
      userTable: userTableInterface;
      index: number;
    }>(),
    'Users form status': props<{
      usersFormStatus: usersFormStatusType;
    }>(),
    'Users form validation': props<{
      validation: usersFormValidationType;
    }>(),
    'Users infobox edition values': props<{
      userEditedName: string;
    }>(),
    'Users unset infobox edition values': emptyProps(),
    'Users toggling permission modal': props<{
      isOpen: boolean;
    }>(),
  },
});
