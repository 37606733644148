import { Component, EventEmitter, Input, Output } from '@angular/core';
import classNames from 'classnames';

import { WidgetClickEventType } from './widget.type';

@Component({
  selector: 'ad-widget',
  template: `<button
    #widget
    [class]="{ selected: selected }"
    (click)="widgetClick.emit()"
  >
    <span class="title clamp">
      {{ label }}
    </span>
    <div class="content-ctn info-container">
      <span [ngClass]="infoClasses">{{ info }}</span>
      <div class="status-container" *ngIf="status">
        <ng-content select="[statusIcon]"></ng-content>
        <span class="clamp">{{ status }}</span>
      </div>
    </div>
  </button>`,
  styleUrls: ['./widget.component.scss'],
})
export class WidgetOrganism {
  @Input()
  label = '';

  @Input()
  index?: number;

  @Input()
  info: number | null = null;

  @Input()
  status?: string;

  @Input()
  selected = false;

  @Output()
  widgetClick: EventEmitter<WidgetClickEventType> = new EventEmitter();

  public get infoClasses() {
    return classNames(
      this.info ? (this.info.toString().length > 6 ? 'info--max' : 'info') : ''
    );
  }
}
