/**
 * Back-office API
 * In charge of providing data to the back-office frontend apps
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface VehicleView {
  id: number;
  updatedDate: string;
  name: string | null;
  brand: string | null;
  vehicleType: string | null;
  tagNumber: number | null;
  plateNumber: string | null;
  model: string | null;
  year: number | null;
  vin: string | null;
  homePort: string | null;
  conformEld: VehicleView.ConformEldEnum;
  validRow: boolean;
}
export namespace VehicleView {
  export type ConformEldEnum = 'YES' | 'NO' | 'NOT_APPLICABLE';
  export const ConformEldEnum = {
    Yes: 'YES' as ConformEldEnum,
    No: 'NO' as ConformEldEnum,
    NotApplicable: 'NOT_APPLICABLE' as ConformEldEnum,
  };
}
