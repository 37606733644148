import { notificationTypeEnum } from '../notification-center/notification-center.enums';

const today = new Date().toLocaleDateString('fr-ca');
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export const mockNotifications = [
  {
    id: 1,
    type: notificationTypeEnum.MISE,
    unread: false,
    createdDate: today,
    createdTime: '4:19 PM',
    circuit: 'Circuit 01',
    route: 'Route 01',
    message: '24 Juin 2023 - 4:00 AM A 6:00 AM',
    asset: 'autobus 01',
    assetNum: 1,
    sender: null,
  },
  {
    id: 2,
    type: notificationTypeEnum.ALERT_LOW,
    unread: false,
    createdDate: yesterday.toLocaleDateString('fr-ca'),
    createdTime: '4:21 PM',
    circuit: 'Circuit 1337',
    route: 'Route 01',
    message: '22 fevrier de 11:20 PM a 12 PM',
    asset: 'autobus 1337',
    assetNum: 1337,
    sender: null,
  },
  {
    id: 3,
    type: notificationTypeEnum.ALERT_MEDIUM,
    unread: true,
    createdDate: '2023-12-18',
    createdTime: '3:16 PM',
    circuit: 'Circuit 12',
    route: 'Route 01',
    message: null,
    asset: 'autobus 111',
    assetNum: 111,
    sender: null,
  },
  {
    id: 4,
    type: notificationTypeEnum.ALERT_HIGH,
    unread: false,
    createdDate: '2023-04-20',
    createdTime: '5:20 PM',
    circuit: 'Circuit 123',
    route: 'Route 01',
    message: null,
    asset: 'autobus 15',
    assetNum: 15,
    sender: null,
  },
  {
    id: 5,
    type: notificationTypeEnum.MESSAGE,
    unread: false,
    createdDate: '1970-01-01',
    createdTime: '1:10 AM',
    circuit: null,
    route: 'Route 01',
    message: 'Sent you a message',
    asset: null,
    assetNum: null,
    sender: 'Gordon R AMsay',
  },
];
