import { createReducer, on } from '@ngrx/store';

import { globalReducerInterface } from './global';
import {
  fleetOwnerActions,
  menuActions,
  notificationActions,
} from './global.action';

import { sortNotifications } from './global.helper';

export const globalStateKey = 'globalState';

export const initialState: globalReducerInterface = {
  menu: {
    showMenu: false,
  },
  notifications: [],
  fleetOwnerID: -1,
};

export const globalReducer = createReducer<globalReducerInterface>(
  initialState,
  on(menuActions.closeMenu, (_state) => ({
    ..._state,
    menu: {
      showMenu: false,
    },
  })),
  on(menuActions.toggleMenu, (_state, { showMenu }) => ({
    ..._state,
    menu: { showMenu: !showMenu },
  })),
  on(notificationActions.markAllAsRead, (_state) => ({
    ..._state,
    notifications: _state.notifications.map((notification) => ({
      ...notification,
      unread: false,
    })),
  })),
  on(notificationActions.getNotifications, (_state, { notifications }) => ({
    ..._state,
    notifications: sortNotifications(notifications),
  })),
  on(fleetOwnerActions.fleetOwnerIDLoaded, (_state, { fleetOwnerID }) => ({
    ..._state,
    fleetOwnerID,
  }))
);
