<ng-container *ngFor="let notification of notificationData; trackBy: trackByFn">
  <ng-container [ngSwitch]="notification.type" class="container">
    <!--- Separator with date --->
    <div class="separator text-p3" *ngSwitchCase="'separator'">
      {{ notification.createdDate | translate }}

      <!--- Mark all as read button (Only appears on the first separator, and when there are unread notifications) --->
      <div class="button text-caption--semibold" *ngIf="notification.id === 0">
        <button [disabled]="notificationCount <= 0" (click)="markAllAsRead()">
          {{ 'NOTIFICATION_CENTER.READ_ALL' | translate }}
        </button>
      </div>
    </div>

    <!--- Alert high --->
    <ad-notification-card
      *ngSwitchCase="'alert-high'"
      [notificationType]="notificationTypeEnum.ALERT_HIGH"
      [number]="notification.assetNum || undefined"
      [time]="notification.createdTime"
      [unread]="notification.unread"
    >
      <p class="text-p2">
        <span class="text-p2--semibold">{{ notification.asset }}</span>
        {{ 'NOTIFICATION_CENTER.ALERT_HIGH' | translate }}
        {{ notification.circuit }}
      </p>
    </ad-notification-card>

    <!--- Alert medium --->
    <ad-notification-card
      *ngSwitchCase="'alert-medium'"
      [notificationType]="notificationTypeEnum.ALERT_MEDIUM"
      [number]="notification.assetNum || undefined"
      [time]="notification.createdTime"
      [unread]="notification.unread"
    >
      <p class="text-p2">
        <span class="text-p2--semibold">
          {{ 'NOTIFICATION_CENTER.ALERT_MEDIUM' | translate }}
          {{ notification.asset }}</span
        >
        <br />
        {{ notification.circuit }}
      </p>
    </ad-notification-card>

    <!--- Alert low --->
    <ad-notification-card
      *ngSwitchCase="'alert-low'"
      [notificationType]="notificationTypeEnum.ALERT_LOW"
      glyph="circuit"
      [time]="notification.createdTime"
      [unread]="notification.unread"
    >
      <p class="text-p2">
        {{ 'NOTIFICATION_CENTER.ALERT_LOW.ADDED' | translate }}

        <span class="text-p2--semibold">{{ notification.route }}</span>

        {{ 'NOTIFICATION_CENTER.ALERT_LOW.ON' | translate }}

        <span class="text-p2--semibold">{{ notification.circuit }}</span>
        |
        {{ notification.message }}
      </p>
    </ad-notification-card>

    <!--- Mise a jour --->
    <ad-notification-card
      *ngSwitchCase="'mise'"
      [notificationType]="notificationTypeEnum.MISE"
      glyph="settings"
      [time]="notification.createdTime"
      [unread]="notification.unread"
    >
      <p class="text-p2">
        {{ 'NOTIFICATION_CENTER.MISE' | translate }}
        <br />
        <span class="text-p2--semibold">{{ notification.message }}</span>
      </p>
    </ad-notification-card>

    <!--- Message --->
    <ad-notification-card
      *ngSwitchCase="'message'"
      [notificationType]="notificationTypeEnum.MESSAGE"
      glyph="settings"
      [time]="notification.createdTime"
      [unread]="notification.unread"
    >
      <p class="text-p2">
        {{ 'NOTIFICATION_CENTER.MESSAGE' | translate }}
        <span class="text-p2--semibold">{{ notification.sender }}</span>
      </p>
    </ad-notification-card>
  </ng-container>
</ng-container>
