import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  countersStoreInterface,
  InfoboxDataType,
  InfoboxHeaderType,
  resourcesReducerErrorType,
} from './resources';

export const resourcesAPIActions = createActionGroup({
  source: 'Resources API',
  events: {
    'Update infobox data': props<{ infoboxData: InfoboxDataType }>(),
    'Update infobox header': props<{ infoboxHeader: InfoboxHeaderType }>(),
    'Fleet Owner ID loaded': props<{ fleetOwnerID: number }>(),
    'Clear infobox header': emptyProps(),
    'Resources counters loading': emptyProps(),
    'Resources counters loaded': props<{
      resourcesCounters: countersStoreInterface;
    }>(),
    'Resources counters error': props<resourcesReducerErrorType>(),
  },
});
