import { VehicleView } from '@backoffice/ng-backoffice-api-client';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { resourcesOpenedEntryType } from '../../store/resources';

import { vehicleTableInterface } from '../vehicles';

import { vehiclesReducerErrorType } from './vehicles';

export const vehiclesAPIActions = createActionGroup({
  source: 'Vehicles API',
  events: {
    'Vehicles set current ID': props<{
      vehiclesOpenedEntry: resourcesOpenedEntryType;
    }>(),
    'Vehicles unset current ID': emptyProps(),
    'Vehicles loading': emptyProps(),
    'Vehicles loaded': props<{
      vehiclesList: VehicleView[];
      vehiclesTableData: vehicleTableInterface[];
    }>(),
    'Vehicles error': props<vehiclesReducerErrorType>(),
  },
});
