import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { errorStoreInterface } from '../../store/reports';

import { DvirSearchBarFilter, ReportsDvirsInterface } from './reports-dvirs';

export const reportsDvirsAPIActions = createActionGroup({
  source: 'Reports API',
  events: {
    'Dvirs reports loading': emptyProps(),
    'Dvirs reports loaded': props<{ reportsDvirs: ReportsDvirsInterface }>(),
    'Dvirs reports error': props<{ error: errorStoreInterface }>(),
    'Dvirs datatable page change': emptyProps(),
    'Dvirs search bar update': props<{ searchBar: DvirSearchBarFilter }>(),
  },
});
