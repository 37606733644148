import { DatePipe } from '@angular/common';
import { VehicleView } from '@backoffice/ng-backoffice-api-client';
import { TranslateService } from '@ngx-translate/core';

import { vehicleTableInterface } from '../vehicles';

const DATE_FORMAT_REGION = ['en-US', 'fr-CA'].includes(navigator.language)
  ? navigator.language
  : 'en-US';
const datePipe = new DatePipe(DATE_FORMAT_REGION);

export const handleVehicleDataTable = (
  vehicle: VehicleView,
  translateService: TranslateService
): vehicleTableInterface => {
  const {
    id,
    updatedDate,
    name,
    brand,
    vehicleType,
    tagNumber,
    plateNumber,
    model,
    year,
    vin,
    homePort,
    conformEld,
    validRow,
  } = vehicle;
  return {
    id,
    updatedDate: [
      handleUpdateDate(updatedDate, translateService),
      {
        dataType: 'DATE',
        data: updatedDate,
      },
    ],
    name,
    brand,
    vehicleType: vehicleType
      ? translateService.instant(vehicleType)
      : undefined,
    tagNumber,
    plateNumber,
    model,
    year,
    vin,
    homePort,
    conformEld: [
      translateService.instant(conformEld),
      {
        dataType: 'CELL_VALIDITY',
        data: conformEld !== VehicleView.ConformEldEnum.No,
      },
    ],
    validRow: validRow,
  };
};

const handleUpdateDate = (
  date: string,
  translateService: TranslateService
): string => {
  return (
    datePipe.transform(date, 'short', DATE_FORMAT_REGION)?.replace(/,/g, '') ??
    translateService.instant('USERS.NO_DATE')
  );
};
