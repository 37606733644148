import {
  DropdownItemInterface,
  OverlayItem,
  SearchBarEldInput,
  SearchBarInput,
} from '@common/ng-design-system';

import { ReportModal } from '../enums';

import { ResourcesType } from './resource';

export const UNIDENTIFIED_DRIVER_RESOURCE_ID = -1;

const resourceFilterListItems: DropdownItemInterface[] = [
  {
    value: ResourcesType.ALL,
    label: 'REPORTING_TOOLS.SEARCH_FILTER.ALL',
  },
  {
    value: ResourcesType.DRIVER,
    label: 'REPORTING_TOOLS.SEARCH_FILTER.DRIVER',
  },
  {
    value: ResourcesType.VEHICLE,
    label: 'REPORTING_TOOLS.SEARCH_FILTER.VEHICLE',
  },
];

export const shareButtonOverlayItems: OverlayItem[] = [
  {
    id: ReportModal.DATA_TRANSFER,
    label: 'REPORTING_TOOLS.SHARE_OVERLAY.DATA_TRANSFER',
    icon: 'data_transfer',
  },
];

export const exportSecondOverlayButtonOverlayItems: OverlayItem[] = [
  {
    id: ReportModal.REPORTS_PDF,
    label: 'REPORTING_TOOLS.EXPORT_OVERLAY.REPORTS_PDF',
  },
  {
    id: ReportModal.REPORTS_CSV,
    label: 'REPORTING_TOOLS.EXPORT_OVERLAY.REPORTS_CSV',
  },
];

export const exportFirstOverlayButtonOverlayItems: OverlayItem[] = [
  {
    id: ReportModal.TABLE_PDF,
    label: 'REPORTING_TOOLS.EXPORT_OVERLAY.TABLE_PDF',
  },
  {
    id: ReportModal.TABLE_CSV,
    label: 'REPORTING_TOOLS.EXPORT_OVERLAY.TABLE_CSV',
  },
];

export const exportRDSSecondOverlayButtonOverlayItems: OverlayItem[] = [
  {
    id: ReportModal.REPORTS_PDF,
    label: 'REPORTING_TOOLS.EXPORT_OVERLAY.REPORTS_PDF',
  },
];

export const exportRDSFirstOverlayButtonOverlayItems: OverlayItem[] = [
  {
    id: ReportModal.TABLE_PDF,
    label: 'REPORTING_TOOLS.EXPORT_OVERLAY.TABLE_PDF',
  },
];

export const reportSortListItems: DropdownItemInterface[] = [
  {
    value: 'today',
    label: 'REPORTING_TOOLS.TIME_FILTERS.TODAY',
  },
  {
    value: 'yesterday',
    label: 'REPORTING_TOOLS.TIME_FILTERS.YESTERDAY',
  },
  {
    value: 'last_seven_days',
    label: 'REPORTING_TOOLS.TIME_FILTERS.7_DAYS',
  },
  {
    value: 'last_fourteen_days',
    label: 'REPORTING_TOOLS.TIME_FILTERS.14_DAYS',
  },
  {
    value: 'last_thirty_days',
    label: 'REPORTING_TOOLS.TIME_FILTERS.30_DAYS',
  },
  {
    value: 'last_month',
    label: 'REPORTING_TOOLS.TIME_FILTERS.THIS_MONTH',
  },
  {
    value: 'last_six_months',
    label: 'REPORTING_TOOLS.TIME_FILTERS.LAST_SIX_MONTHS',
  },
  {
    value: 'last_year',
    label: 'REPORTING_TOOLS.TIME_FILTERS.LAST_YEAR',
  },
  {
    value: 'this_year',
    label: 'REPORTING_TOOLS.TIME_FILTERS.THIS_YEAR',
  },
  {
    value: 'last_two_years',
    label: 'REPORTING_TOOLS.TIME_FILTERS.LAST_TWO_YEARS',
  },
];

export const reportSecondSortListItems: DropdownItemInterface[] = [
  // TODO: implement custom date in the future
  // {
  //   value: 'custom_period',
  //   label: 'REPORTING_TOOLS.TIME_FILTERS.PERSONALIZED_PERIOD',
  // },
];

export const typeResourcesData: DropdownItemInterface[] = [
  {
    value: ResourcesType.DRIVER,
    label: 'REPORTING_TOOLS.SEARCH_FILTER.DRIVER',
  },
  {
    value: ResourcesType.VEHICLE,
    label: 'REPORTING_TOOLS.SEARCH_FILTER.VEHICLE',
  },
];

// default data for search bar
export const searchBarInput: SearchBarInput = {
  dataSortInput: {
    firstSortOptions: reportSortListItems,
    secondSortOptions: reportSecondSortListItems,
  },
  searchFilterInput: {
    options: resourceFilterListItems,
    placeholder: 'REPORTING_TOOLS.ACTIONS.SEARCH',
  },
  exportInput: {
    buttonText: 'REPORTING_TOOLS.ACTIONS.EXPORT',
    overlayItems: exportRDSFirstOverlayButtonOverlayItems,
    secondOverlayItems: exportRDSSecondOverlayButtonOverlayItems,
  },
  shareInput: {
    overlayItems: shareButtonOverlayItems,
  },
  shareButton: {
    visible: false,
    enabled: false,
  },
  exportButton: {
    visible: true,
    enabled: false,
  },
};

// default data for eld search bar
export const eldSearchBarInput: SearchBarEldInput = {
  dataSortInput: {
    firstSortOptions: reportSortListItems,
    secondSortOptions: reportSecondSortListItems,
  },
  resourcesSelectOptions: {
    isLoading: false,
    resourcesData: [],
    placeholder: 'REPORTING_TOOLS.SELECT_PLACEHOLDERS.SELECT_RESOURCES',
  },
  typeSelectOptions: {
    placeholder: 'REPORTING_TOOLS.SELECT_PLACEHOLDERS.SELECT_TYPE',
    typeResourcesData: typeResourcesData,
  },
  exportInput: {
    buttonText: 'REPORTING_TOOLS.ACTIONS.EXPORT',
    disableTextAlert: 'REPORTS.EXPORT_DISABLED',
    overlayItems: exportFirstOverlayButtonOverlayItems,
    secondOverlayItems: exportSecondOverlayButtonOverlayItems,
  },
  shareInput: {
    overlayItems: shareButtonOverlayItems,
  },

  shareButton: {
    visible: true,
    enabled: false,
  },
  exportButton: {
    visible: true,
    enabled: false,
  },
};
