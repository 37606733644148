import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IconAtomType } from '../../atoms';

@Component({
  selector: 'ad-drawer-header',
  templateUrl: './navigation-drawer-header.component.html',
  styleUrls: ['./navigation-drawer-header.component.scss'],
})
export class NavigationDrawerHeaderMolecule {
  @Input() glyph: Extract<IconAtomType, 'close' | 'chevron_left'> = 'close';

  @Input() title = 'drawer Header';

  @Output() iconClicked = new EventEmitter<boolean>();

  isButtonActive = false;

  onIconClick(): void {
    this.iconClicked.emit();
  }
}
