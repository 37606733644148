import { assert } from '@common/utils';

export enum tableMetaDataType {
  DATE = 'DATE',
  CELL_VALIDITY = 'CELL_VALIDITY',
}

export const compareMetaData = {
  [tableMetaDataType.DATE]: (a: unknown, b: unknown) => {
    assert(typeof a === 'string' && typeof b === 'string');
    const iso8601Regex =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|[+-]\d{2}:\d{2})$/;

    if (iso8601Regex.test(a) && iso8601Regex.test(b)) {
      const dateA = new Date(a).getTime();
      const dateB = new Date(b).getTime();
      return dateA - dateB;
    }
    return 0;
  },
  [tableMetaDataType.CELL_VALIDITY]: (a: unknown, b: unknown) => {
    assert(typeof a === 'boolean' && typeof b === 'boolean');
    return a === b ? 0 : a ? 1 : -1;
  },
};
