import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { colors } from '../../common-ng-design-system.colors';

@Component({
  selector: 'ad-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeAtom implements OnChanges {
  _color: keyof typeof colors = 'neutral-900';

  @Input() digit = 0;

  badgeType: 'no-digit' | 'single-digit' | 'multiple-digit' = 'no-digit';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['digit']) {
      this.badgeType = this.checkDigit();
    }
  }

  @HostBinding('style.--ad-badge-color')
  get badgeColor(): string {
    return this._color ? `rgb(var(--color-${this._color}))` : '';
  }

  @Input()
  public get color(): keyof typeof colors {
    return this._color;
  }
  public set color(val: keyof typeof colors) {
    this._color = val;
  }

  checkDigit() {
    if (this.digit === 0) {
      return 'no-digit';
    }
    const digitString = String(this.digit).trim();

    if (/^\d$/.test(digitString)) {
      return 'single-digit';
    } else if (
      (/^\d+$/.test(digitString) && !/\./.test(digitString)) ||
      this.isNumber(this.digit)
    ) {
      return 'multiple-digit';
    } else {
      return 'no-digit';
    }
  }

  /**
   *
   * @param value - The value to be checked
   * @returns {boolean} - Returns true if the value is a number
   * @description - This function checks if the value is a number, necessary for numbers in scientific notation.
   */
  isNumber(value: string | number): boolean {
    return typeof value === 'number' && !Number.isNaN(value);
  }

  /**
   * @returns {string} - Returns the formatted digit
   * @description - This function converts the digit value to a formatted string for displaying in the UI.
   */
  getFormattedDigit() {
    return this.digit > 1000 ? '999+' : this.digit === 0 ? '' : this.digit;
  }

  /**
   *
   * @returns {string} - Returns the CSS classes to be applied to the badge.
   * @description - This function returns the CSS classes to be applied to the badge.
   */
  getBadgeClasses() {
    const badgeType = this.checkDigit();
    return ['badge-container', badgeType].join(' ');
  }

  /**
   *
   * @returns {string} - Returns the CSS classes to be applied to the digit.
   * @description - This function checks if the number of digits in the badge is equal to one.
   */
  getDigitClasses() {
    const badgeType = this.checkDigit();

    return [
      'text-p3 badge-digit',
      badgeType === 'single-digit' ? 'single-digit' : '',
      badgeType === 'multiple-digit' ? 'multiple-digit' : '',
    ].join(' ');
  }
}
