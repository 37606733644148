import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Custom validator function that checks if the control value is a duplicate of any existing values.
 * @param existingValues An array of existing values to compare against.
 * @returns A validator function that returns a ValidationErrors object if the control value is a duplicate, or null if it is not.
 */
export const duplicateValidator = (existingValues: string[]): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      // The value is empty, which is considered valid.
      return null;
    }

    const duplicateExists = existingValues.includes(control.value);

    if (duplicateExists) {
      // The value is valid or empty
      return { duplicateValues: true };
    } else {
      return null; // Value is not in the valid list
    }
  };
};

/**
 * Validates a phone number using a regular expression pattern.
 * @param phone - The phone number to validate.
 * @returns A boolean indicating whether the phone number is valid or not.
 */
export const phoneValidator = (phone: string): boolean => {
  // Regular expression for phone validation
  const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;

  // Check if the input value matches the regex pattern
  return phonePattern.test(phone);
};

/**
 * Validates a phone form control.
 *
 * @param control - The phone form control to validate.
 * @returns A validation error object if the control is invalid, otherwise null.
 */
export const phoneFormValidator = (control: AbstractControl) => {
  if (
    !control.value ||
    typeof control.value !== 'string' ||
    (typeof control.value === 'string' && control.value.length === 0)
  ) {
    return null; // no value input
  }

  const phoneNumbers = control.value?.split(',');
  const invalidPhones = phoneNumbers.filter((phone: string) => {
    return !phoneValidator(phone);
  }); // filter invalid phones

  if (invalidPhones.length > 0) {
    return { invalidPhones: true }; // invalid phones
  } else {
    return null; // all phones valid
  }
};

/**
 * Validates an email address using a regular expression pattern.
 * @param email - The email address to be validated.
 * @returns A boolean indicating whether the email address is valid or not.
 */
export const emailValidator = (email: string): boolean => {
  // Regular expression for email validation
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Check if the input value matches the regex pattern
  return emailPattern.test(email);
};

/**
 * Validates an email form control.
 *
 * @param control - The form control to validate.
 * @returns A validation error object if the control value is invalid, otherwise null.
 */
export const emailFormValidator = (control: AbstractControl) => {
  if (
    !control.value ||
    typeof control.value !== 'string' ||
    (typeof control.value === 'string' && control.value.length === 0)
  ) {
    return null; // valid email
  }

  const emails = control.value.toString().split(',');

  const invalidEmails = emails.filter((email: string) => {
    if (email === '') return false; // filter empty emails
    return !emailValidator(email.trim());
  }); // filter invalid emails

  if (!invalidEmails.length) {
    return null; // all emails valid
  } else {
    return { invalidEmails: true }; // invalid emails
  }
};

/**
 * Custom validator function for checking the length of a value.
 * Returns an error object if the value length is less than 4 characters.
 * Returns null if the value length is 4 or more characters.
 *
 * @param control - The form control to validate.
 * @returns An error object if the value length is less than 4 characters, otherwise null.
 */
export const exemptionValidator = (control: AbstractControl) => {
  const value = control.value || '';
  return value.length >= 4 ? null : { minLength: true };
};

/**
 * Custom validator function that checks if a control value contains only whitespace characters.
 * Returns a validation error if the value is empty or contains only whitespace.
 *
 * @param control - The form control to validate.
 * @returns An object with the `whitespace` property set to `true` if the value is empty or contains only whitespace, otherwise `null`.
 */
export const noWhitespaceValidator = (control: AbstractControl) => {
  return (control.value || '').trim().length ? null : { whitespace: true };
};
