import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ad-chip-search',
  templateUrl: './chip-search.component.html',
  styleUrls: ['./chip-search.component.scss'],
})
export class ChipSearchMolecule {
  @Input()
  showChip = true;

  @Input()
  value = '';

  @Output()
  chipHasDismissed: EventEmitter<void> = new EventEmitter();

  isClosing = false;

  /**
   * This method is handled the click on the chip close button.
   **/
  onChipClosing() {
    this.isClosing = true;
    setTimeout(() => {
      this.chipHasDismissed.emit();
    }, 500);
  }
}
