<div class="content header-compensating-padding-top">
  <img src="{{ backgroundImage }}" />
  <h3 class="text-h3--semibold">
    {{ 'UNAUTHORIZED_TEXT.TITLE' | translate }}
  </h3>
  <p class="text-p1">
    {{ 'UNAUTHORIZED_TEXT.DESCRIPTION' | translate }}
  </p>
  <p class="text-p1">
    {{ 'UNAUTHORIZED_TEXT.END_DESCRIPTION' | translate }}
  </p>
  <button
    astus-design-button
    kind="filled"
    color="primary"
    size="md"
    (click)="logout()"
  >
    {{ 'UNAUTHORIZED_BTN.LOGOUT' | translate }}
  </button>
</div>
