/**
 * Back-office API
 * In charge of providing data to the back-office frontend apps
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UserPermissionView {
  permissions: Array<UserPermissionView.PermissionsEnum>;
}
export namespace UserPermissionView {
  export type PermissionsEnum =
    | 'eld:manager'
    | 'astus:fms:FleetManager'
    | 'astus:fms:ManagerManager';
  export const PermissionsEnum = {
    Eldmanager: 'eld:manager' as PermissionsEnum,
    AstusfmsFleetManager: 'astus:fms:FleetManager' as PermissionsEnum,
    AstusfmsManagerManager: 'astus:fms:ManagerManager' as PermissionsEnum,
  };
}
