<button
  type="button"
  class="menu-button"
  [ngClass]="{ 'menu-button--close': this.showMenu }"
  (click)="toggleMenu(showMenu)"
>
  <div class="bar"></div>
  <div class="bar"></div>
  <div class="bar"></div>
</button>
<div class="title" *ngIf="topBarTitle">
  {{ topBarTitle | translate }}
</div>
