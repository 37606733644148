import { Injectable } from '@angular/core';
import { ReportsService } from '@backoffice/ng-backoffice-api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';

import { calculateDate } from '../../../../utils/time.util';

import { toBackendResourcesType } from '../../constants/resource';

import { reportsDvirsAPIActions } from './reports-dvirs.actions';

import {
  selectStoreDvirsPagination,
  selectStoreReportsDvirsFeature,
  selectStoreSearchBarFilterFeature,
} from './reports-dvirs.selectors';

@Injectable()
export class ReportsDvirsEffects {
  constructor(
    private actions$: Actions,
    private readonly reportsService: ReportsService,
    private readonly store: Store
  ) {}

  readonly getDvirsReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        reportsDvirsAPIActions.dvirsReportsLoading,
        reportsDvirsAPIActions.dvirsSearchBarUpdate
      ),
      withLatestFrom(
        this.store.pipe(select(selectStoreDvirsPagination)),
        this.store.pipe(select(selectStoreSearchBarFilterFeature))
      ),
      exhaustMap(([, pagination, searchBar]) => {
        return this.reportsService
          .reportsControllerFindDvirs(
            pagination.currentPage,
            'application/json',
            calculateDate(searchBar.date)?.toISOString(),
            undefined,
            toBackendResourcesType(searchBar.resourcesType)
          )
          .pipe(
            map((dvirsReports) => ({
              type: reportsDvirsAPIActions.dvirsReportsLoaded.type,
              reportsDvirs: {
                loaded: true,
                dvirs: dvirsReports.reports,
                error: null,
                searchBar: searchBar,
                pagination: {
                  currentPage: dvirsReports.currentPage,
                  totalPage: dvirsReports.totalPage,
                },
              },
            })),
            catchError((error: unknown) =>
              of({
                type: reportsDvirsAPIActions.dvirsReportsError.type,
                error: {
                  message: 'Error loading dvirs',
                  error: JSON.stringify(error),
                },
              })
            )
          );
      })
    )
  );

  readonly infiniteScrollingDvirsReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportsDvirsAPIActions.dvirsDatatablePageChange),
      withLatestFrom(
        this.store.pipe(select(selectStoreReportsDvirsFeature)),
        this.store.pipe(select(selectStoreSearchBarFilterFeature))
      ),
      exhaustMap(([, reportsDvirs]) => {
        const { pagination, dvirs, searchBar } = reportsDvirs;
        const newPage = pagination.currentPage + 1;
        return this.reportsService
          .reportsControllerFindDvirs(
            pagination.currentPage,
            'application/json',
            calculateDate(searchBar.date)?.toISOString(),
            undefined,
            toBackendResourcesType(searchBar.resourcesType)
          )
          .pipe(
            map((dvirsReports) => ({
              type: reportsDvirsAPIActions.dvirsReportsLoaded.type,
              reportsDvirs: {
                loaded: true,
                dvirs: [...dvirs, ...dvirsReports.reports],
                error: null,
                searchBar: searchBar,
                pagination: {
                  currentPage: newPage,
                  totalPage: dvirsReports.totalPage,
                },
              },
            })),
            catchError((error: unknown) =>
              of({
                type: reportsDvirsAPIActions.dvirsReportsError.type,
                error: {
                  message: 'Error loading dvirs',
                  error: JSON.stringify(error),
                },
              })
            )
          );
      })
    )
  );
}
