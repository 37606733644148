import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import classNames from 'classnames';

import { IconAtomGlyphType } from '../../atoms';

@Component({
  selector: 'ad-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SwitchMolecule,
      multi: true,
    },
  ],
})
export class SwitchMolecule implements ControlValueAccessor {
  @Input() checked = false;

  @Input() iconGlyph!: IconAtomGlyphType;

  @Input() disabled = false;

  @Input() size: 'sm' | 'md' = 'md';

  @Input() icon = true;

  @Output() checkedChange = new EventEmitter<boolean>();

  /**
   * Toggle the checked state of the checkbox.
   */
  onToggle() {
    if (!this.disabled) {
      this.checked = !this.checked;
      this.checkedChange.emit(this.checked);
    }
  }

  public get buttonContainerClasses() {
    return classNames({
      md: this.size === 'md',
      sm: this.size === 'sm',
      disabled: this.disabled,
      checked: this.checked && !this.disabled,
    });
  }

  public get iconContainerClasses() {
    return classNames({
      md: this.size === 'md',
      sm: this.size === 'sm',
      disabled: this.disabled,
      checked: this.checked,
    });
  }

  writeValue(value: boolean) {
    this.checked = value;
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn;
  }

  onChange = () => {
    return;
  };
  onTouch = () => {
    return;
  };
}
