<button
  class="item-container text-p2"
  [ngClass]="{ 'highlighted-text': highlighted, 'regular-text': !highlighted }"
  adRipple
  (mouseover)="onMouseEvent($event)"
  (mouseout)="onMouseEvent($event)"
  (focus)="isButtonActive = true"
  (blur)="isButtonActive = false"
  (click)="onItemClick($event)"
  [disabled]="disabled"
  #buttonRef
>
  <div class="label-container">
    <div *ngIf="icon" class="icon-container">
      <ad-icon [glyph]="icon" [color]="getColor()"></ad-icon>
    </div>
    <div class="label-text">
      <span>{{ label }}</span>
    </div>
  </div>
  <div class="badge-container">
    <ad-badge
      *ngIf="notificationType === 'no-digit'"
      [color]="getColor()"
    ></ad-badge>
    <ad-badge
      *ngIf="notificationType === 'digit' && notificationCount"
      [color]="getColor()"
      [digit]="notificationCount"
    ></ad-badge>
  </div>
</button>
