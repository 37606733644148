<div class="search-bar-eld-container">
  <div class="select-container">
    <div class="select-input-container">
      <!-- Date select input -->
      <ad-dropdown
        [items]="searchBarInput.dataSortInput.firstSortOptions"
        [itemsSecondList]="searchBarInput.dataSortInput.secondSortOptions"
        (dropdownElementChange)="onDateItemSelection($event)"
        [value]="selectedDateOptions!.value"
        class="select"
      ></ad-dropdown>
    </div>

    <div class="select-input-container">
      <!-- Resources type select input -->
      <!-- Render the type input only when we the resources selection option is loaded -->
      <ad-dropdown
        *ngIf="!searchBarInput.resourcesSelectOptions.isLoading"
        [items]="searchBarInput.typeSelectOptions.typeResourcesData"
        [placeholder]="searchBarInput.typeSelectOptions.placeholder"
        (dropdownElementChange)="onTypeItemSelection($event)"
        [allowEmptyOption]="true"
        class="select"
      ></ad-dropdown>

      <ad-skeleton
        *ngIf="searchBarInput.resourcesSelectOptions.isLoading"
        height="40px"
        width="278px"
        animation="pulse"
        shape="rectangle"
      ></ad-skeleton>
    </div>

    <div class="select-input-container">
      <!-- Resources data select input -->
      <ad-dropdown
        *ngIf="!searchBarInput.resourcesSelectOptions.isLoading"
        [items]="searchBarInput.resourcesSelectOptions.resourcesData"
        (dropdownElementChange)="onResourceItemSelection($event)"
        [disabled]="!searchBarInput.resourcesSelectOptions.resourcesData.length"
        [placeholder]="searchBarInput.resourcesSelectOptions.placeholder"
        [value]="selectedResourceOptions ? selectedResourceOptions.value : null"
        class="select"
      ></ad-dropdown>

      <ad-skeleton
        *ngIf="searchBarInput.resourcesSelectOptions.isLoading"
        height="40px"
        width="278px"
        animation="pulse"
        shape="rectangle"
      ></ad-skeleton>
    </div>
  </div>
  <div
    class="export-section-container"
    *ngIf="searchBarInput.exportButton.visible"

  >
    <div (click)="onExportButtonClick()" #export_button class="export-button-container">
      <button
        astus-design-button
        color="neutral"
        kind="outlined"
        [class.disabled]="
          !searchBarInput.exportButton.enabled ||
          !selectedResourceOptions?.value
        "
        size="sm"
      >
        {{ searchBarInput.exportInput.buttonText }}
      </button>

      <!-- button menu overlay -->
      <ng-container
        *ngTemplateOutlet="
          buttonOverlay;
          context: {
            overlaysItems: searchBarInput.exportInput.secondOverlayItems,
            secondOverlaysItems: null,
            isExportButton: true,
            overlayIsOpen: exportMenuIsOpen
          }
        "
      ></ng-container>
    </div>

    <!-- Share icon button -->
    <div
      #share_button
      class="share-icon-container"
      *ngIf="searchBarInput.shareButton.visible"
    >
      <ng-container>
        <div class="icon-container">
          <ad-icon
            glyph="share"
            [color]="
              searchBarInput.shareButton.enabled &&
              selectedResourceOptions?.value
                ? 'neutral-900'
                : 'neutral-400'
            "
            class="share-icon"
            [ngClass]="{
              'share-icon--disabled': !(
                searchBarInput.shareButton.enabled &&
                selectedResourceOptions?.value
              )
            }"
            (click)="onShareIconClick()"
          ></ad-icon>
        </div>

        <!-- button menu overlay for share icon -->
        <ng-container
          *ngTemplateOutlet="
            buttonOverlay;
            context: {
              overlaysItems: searchBarInput.shareInput.overlayItems,
              secondOverlaysItems: null,
              isExportButton: false,
              overlayIsOpen: shareMenuIsOpen
            }
          "
        ></ng-container>
      </ng-container>
    </div>
  </div>

  <!-- Template to render the overlay for share / export button -->
  <ng-template
    #buttonOverlay
    let-overlaysItems="overlaysItems"
    let-secondOverlaysItems="secondOverlaysItems"
    let-isExportButton="isExportButton"
    let-overlayIsOpen="overlayIsOpen"
  >
    <!-- button menu overlay -->
    <div
      class="overlay-menu"
      [ngClass]="{
        'overlay-menu--open': overlayIsOpen,
        'overlay-menu--close': !overlayIsOpen,
      }"
    >
      <div
        class="overlay-menu-content"
        [ngClass]="{ 'share-menu-content-overlay': !isExportButton }"
      >
        <ng-container *ngFor="let item of overlaysItems; let i = index">
          <button
            class="overlay-menu-item text-p3"
            (click)="
              isExportButton
                ? onExportMenuItemClick(item)
                : onShareMenuItemClick(item)
            "
          >
            <!--If item to display in the overlay has icon we display it -->
            <ad-icon
              *ngIf="item.icon"
              [glyph]="item.icon"
              color="primary-400"
              class="overlay-menu-item-icon"
            ></ad-icon>

            <span class="clamp">{{ item.label }}</span>
          </button>
        </ng-container>

        <!-- add a divider only if template has second overlay items list -->
        <div class="divider" *ngIf="secondOverlaysItems !== null"></div>

        <ng-container *ngFor="let item of secondOverlaysItems; let i = index">
          <button
            class="overlay-menu-item text-p3"
            (click)="
              isExportButton
                ? onExportMenuItemClick(item)
                : onShareMenuItemClick(item)
            "
          >
            <!--If item to display in the overlay has icon we display it -->
            <ad-icon
              *ngIf="item.icon"
              [glyph]="item.icon"
              color="primary-400"
              class="overlay-menu-item-icon"
            ></ad-icon>

            <span class="clamp">{{ item.label }}</span>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
