import { DateTime } from 'luxon';

type ISODateString = string & { readonly brand: unique symbol };

/**
 * DateUtil class provides utility methods for date and time manipulation.
 */
export class DateUtil {
  private readonly locale: string;

  constructor() {
    this.locale = navigator.language ?? 'en';
  }

  /**
   * Converts an ISO date string to a short date with 24-hour time format.
   * @param {string} dateString - An ISO date string.
   * @returns {string} A string representing the date and time in short date and 24-hour time format.
   */
  fromISOToShortDateWithTime24(dateString: string): string {
    const dateTime = DateTime.fromISO(dateString).setLocale(this.locale);
    if (!dateTime.isValid) {
      throw new Error(`Invalid date string: ${dateString}`);
    }
    const shortDate = dateTime.toLocaleString(DateTime.DATE_SHORT);
    const time = dateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
    return `${shortDate} ${time}`;
  }

  /**
   * Converts two ISO date strings to a relative date string.
   * @param {string} dateString1 - The first ISO date string.
   * @param {string} dateString2 - The second ISO date string.
   * @returns {string} A string representing the relative date between the two dates.
   */
  fromISOsToRelativeDate(dateString1: string, dateString2: string): string {
    const dateTime1 = DateTime.fromISO(dateString1).setLocale(this.locale);
    const dateTime2 = DateTime.fromISO(dateString2).setLocale(this.locale);
    const isDateTime1BeforeDateTime2 = dateTime1 <= dateTime2;
    if (dateTime1.hasSame(dateTime2, 'day')) {
      const date = dateTime1.toLocaleString(DateTime.DATE_SHORT);
      const time1 = dateTime1.toLocaleString(DateTime.TIME_24_SIMPLE);
      const time2 = dateTime2.toLocaleString(DateTime.TIME_24_SIMPLE);
      const time = isDateTime1BeforeDateTime2
        ? `${time1} - ${time2}`
        : `${time2} - ${time1}`;

      return `${date} ${time}`;
    }
    const date1 = this.fromISOToShortDateWithTime24(dateString1);
    const date2 = this.fromISOToShortDateWithTime24(dateString2);

    return isDateTime1BeforeDateTime2
      ? `${date1} - ${date2}`
      : `${date2} - ${date1}`;
  }
}
