<astus-topbar [(showMenu)]="showMenu"></astus-topbar>
<astus-menu [(showMenu)]="showMenu"></astus-menu>

<div class="content header-compensating-padding-top">
  <img src="{{ backgroundImage }}" />
  <h3 class="text-h3--semibold">
    {{ 'TEXT.TITLE' | translate }}
  </h3>
  <p class="text-p1">
    {{ 'TEXT.DESCRIPTION' | translate }}
  </p>
  <p class="text-p1">
    {{ 'TEXT.END_DESCRIPTION' | translate }}
  </p>
  <button
    astus-design-button
    kind="filled"
    color="primary"
    size="md"
    (click)="goBack()"
  >
    {{ 'CTA.GO_BACK' | translate }}
  </button>
</div>
