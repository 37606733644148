import { createFeatureSelector, createSelector } from '@ngrx/store';

import { usersReducerInterface } from './users';
import { usersFeatureKey } from './users.reducer';

export const usersFeature =
  createFeatureSelector<usersReducerInterface>(usersFeatureKey);

export const selectUsersFeature = createSelector(usersFeature, (data) => {
  return {
    usersList: [...data.usersList].sort((a, b) => {
      const aFullName = `${a.firstName} ${a.lastName}`.trim();
      const bFullName = `${b.firstName} ${b.lastName}`.trim();
      return aFullName?.localeCompare(bFullName ?? '') ?? 0;
    }),
    usersTableData: data.usersTableData,
    usersOpenedEntry: data.usersOpenedEntry,
  };
});

export const selectUsersCurrentIdFeature = createSelector(
  usersFeature,
  (data) => data.usersOpenedEntry
);

export const selectUsersFormFeature = createSelector(
  usersFeature,
  (data) => data.usersFormStatus
);

export const selectUsersFormValidationFeature = createSelector(
  usersFeature,
  (data) => data.usersFormValidation
);

export const selectUsersFormCreateFeature = createSelector(
  usersFeature,
  (data) => data.usersFormCreate
);

export const selectUsersFormErrorFeature = createSelector(
  usersFeature,
  (data) => data.error
);

export const selectUsersModalFeature = createSelector(
  usersFeature,
  (data) => data.isModalOpen
);

export const selectUsersUpdateIdFeature = createSelector(
  usersFeature,
  (data) => data.userUpdateID
);
