import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { errorStoreInterface } from '../../store/reports';

import { EldSearchBarFilter, eldStoreInterface } from './reports-elds';

export const reportsEldsAPIActions = createActionGroup({
  source: 'Reports Elds API',
  events: {
    'Eld reports loading': emptyProps(),
    'Eld reports loaded': props<{ eld: eldStoreInterface }>(),
    'Eld reports error': props<{ error: errorStoreInterface }>(),
    'Eld datatable page change': emptyProps(),
    'Eld search bar update': props<{ searchBar: EldSearchBarFilter }>(),
  },
});
