<div class="search-filter-container">
  <!-- Search icon -->
  <ad-icon glyph="search" color="neutral-600" class="icon"></ad-icon>

  <!-- Dropdown -->
  <ad-dropdown
    [items]="sortOptions"
    (dropdownElementChange)="onDropdownValueChange($event)"
    class="sort-options-dropdown"
  ></ad-dropdown>

  <div class="separator"></div>

  <!-- Chips list -->
  <div class="chip-container" #chipsInput>
    <ad-chip-search
      *ngFor="let chip of chips"
      [value]="chip.value"
      [showChip]="true"
      (chipHasDismissed)="onChipCloseClick(chip.id)"
    >
      <ad-icon
        #icon
        glyph="close"
        color="neutral-900"
        class="chip-icon"
      ></ad-icon
    ></ad-chip-search>
  </div>

  <!-- Search input -->
  <div class="search-input" [ngClass]="{ 'no-chips': chips.length === 0 }">
    <input
      #searchInput
      (keyup.enter)="onKeyPress($event)"
      (keydown.Tab)="onKeyPress($event)"
      type="text"
      [placeholder]="searchInputPlaceholder"
      class="input text-p3"
    />
  </div>
</div>
