/**
 * Back-office API
 * In charge of providing data to the back-office frontend apps
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConflictUserView } from './conflictUserView';
import { ExemptionView } from './exemptionView';
import { PermissionView } from './permissionView';
import { ConformityView } from './conformityView';
import { HomePortView } from './homePortView';

export interface DriverView {
  id: number;
  updatedDate: string;
  firstName: string | null;
  lastName: string | null;
  phones: Array<string> | null;
  mails: Array<string> | null;
  defaultEmployeeEmailID: number | null;
  eldLicence: DriverView.EldLicenceEnum | null;
  licenceNumber: string | null;
  licenceIssuingSubDivision: string | null;
  licenceIssuingCountry: string | null;
  homePort: HomePortView | null;
  permissions: Array<PermissionView> | null;
  exemption: ExemptionView;
  conformEld: DriverView.ConformEldEnum;
  validRow: boolean;
  userConflicts: Array<ConflictUserView>;
  isConform: ConformityView;
}
export namespace DriverView {
  export type EldLicenceEnum = 'rds' | 'eld';
  export const EldLicenceEnum = {
    Rds: 'rds' as EldLicenceEnum,
    Eld: 'eld' as EldLicenceEnum,
  };
  export type ConformEldEnum = 'YES' | 'NO' | 'NOT_APPLICABLE';
  export const ConformEldEnum = {
    Yes: 'YES' as ConformEldEnum,
    No: 'NO' as ConformEldEnum,
    NotApplicable: 'NOT_APPLICABLE' as ConformEldEnum,
  };
}
