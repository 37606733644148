<div
  class="main-container no-scrollbar"
  [ngClass]="{
    'is-mobile': isMobile,
    'no-scrollbar': !dataToView || !dataToView.length
  }"
  #table
  (scroll)="onScroll($event)"
>
  <table>
    <thead class="text-caption">
      <tr
        adStickyColumns
        [stickyColumnsCount]="stickyColumns"
        [adIsSticky]="!isMobile"
        [applySticky]="ready && redrawHeader"
      >
        <th
          *ngIf="this.dataType !== 'reports'"
          class="heading-state-cell"
          [ngClass]="{ pinned: !isMobile }"
        >
          <div
            class="heading-state-cell__content"
            (click)="sortData(statusHeader)"
            [ngClass]="{
              'is-visible': statusHeader.isVisible,
              'is-ascending': statusHeader.sorted === 'ascending',
            }"
            (mouseenter)="hoverHeaderEnter(statusHeader)"
            (mouseleave)="hoverHeaderLeave(statusHeader)"
          ></div>
        </th>
        <ng-container *ngFor="let header of headers; let i = index">
          <th
            *ngIf="header.label"
            adTableStickyColumns
            [adIsSticky]="!isMobile"
            [adStickyColumns]="stickyColumns"
            [adStickyIndex]="i"
            [hasStatusColumn]="hasStatusColumn"
            [attr.colspan]="header?.colSpan"
            [ngClass]="{ pinned: !isMobile && i < stickyColumns }"
            (mouseenter)="hoverHeaderEnter(header)"
            (mouseleave)="hoverHeaderLeave(header)"
          >
            <div class="heading-item">
              {{ header.label }}
              <button
                *ngIf="!isIconHeader(header.dataKey)"
                class="heading-item__button"
                [ngClass]="{
                  'is-visible': header.isVisible,
                  'is-descending': header.sorted === 'descending',
                  'is-ascending': header.sorted === 'ascending',
                }"
                (click)="sortData(header)"
              >
                <svg
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.64644 0.686886C3.84171 0.491624 4.15829 0.491625 4.35355 0.686887L7.31311 3.64645C7.62809 3.96143 7.40501 4.5 6.95956 4.5L1.04044 4.5C0.594985 4.5 0.371902 3.96143 0.686884 3.64645L3.64644 0.686886Z"
                  />
                </svg>
                <svg
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.35356 4.14643C4.15829 4.34169 3.84171 4.34169 3.64645 4.14643L0.686888 1.18687C0.371906 0.871883 0.59499 0.333313 1.04044 0.333313L6.95956 0.333313C7.40502 0.333313 7.6281 0.871884 7.31312 1.18687L4.35356 4.14643Z"
                  />
                </svg>
              </button>
            </div>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody class="text-p3" *ngIf="showColumn">
      <tr
        *ngFor="let dataRow of dataToView; let rowIndex = index"
        [ngClass]="{ 'active-data': rowIndex === activeRowIndex }"
        (click)="selectRow(dataRow, rowIndex)"
        (mouseenter)="hoverRowEnter(rowIndex)"
        (mouseleave)="hoverRowLeave()"
        adStickyColumns
        [stickyColumnsCount]="stickyColumns"
        [adIsSticky]="!isMobile"
        [applySticky]="ready && redrawHeader"
        [tableReady]="rowIndex === dataToView.length - 1"
        (loadEnd)="loadEnd()"
      >
        <!-- that for the cerle icon, only when not reports table -->
        <td
          *ngIf="this.dataType !== 'reports'"
          class="body-state-cell"
          [ngClass]="{
            'is-active': this.activeRowIndex === rowIndex,
            'is-hover':
              this.hoverRowIndex === rowIndex &&
              this.activeRowIndex !== rowIndex,
            pinned: !isMobile
          }"
        >
          <div
            class="body-state-cell__content"
            [ngClass]="{ 'is-not-valid': !this.validRowData(dataRow) }"
          ></div>
        </td>

        <td
          *ngFor="let dataKey of dataKeys; let colIndex = index"
          [ngClass]="{
            'is-hover': this.hoverRowIndex === rowIndex,
            'is-active': this.activeRowIndex === rowIndex,
            'is-missing-data': !dataRow[dataKey.key],
            'is-late': fetchMetaData(dataRow[dataKey.key], 'isRed'),
            cell: dataKey.key === 'status',
            'is-not-valid': !this.validCellData(dataRow[dataKey.key]),
            pinned: !isMobile && colIndex < stickyColumns
          }"
          adTableStickyColumns
          [adIsSticky]="!isMobile"
          [adStickyColumns]="stickyColumns"
          [adStickyIndex]="colIndex"
          [hasStatusColumn]="hasStatusColumn"
        >
          <div
            *ngIf="dataKey.key === 'status' && this.dataType === 'reports'"
            class="body-state-cell__content"
            [ngClass]="{
              'is-not-valid':
                dataRow['status'] === 'Erreur' || dataRow['status'] === 'Error',
                'is-in-progress': dataRow['status'] === 'Traitement en cours...' || dataRow['status'] === 'Download in progress...',
            }"
          ></div>

          <ng-container *ngIf="dataRow['icon']">
            <!-- if icon name is equal to the header name -->
            <ad-icon
              *ngIf="dataRow['icon'].name === dataKey.key"
              class="body-state-cell__icon"
              [ngClass]="{
                'is-disabled':
                  dataRow['icon'].isDisabled &&
                  dataRow['icon'].isPossibleToDisable
              }"
              (click)="iconButtonClicked($event, dataRow)"
              [glyph]="dataRow['icon'].icon"
              [color]="setIconColor(dataRow['icon'], dataRow)"
            ></ad-icon>
          </ng-container>

          <!-- if it is an icon header dont populate it with data it will be filled with the icon in the above section -->

          {{
            isIconHeader(dataKey.key, dataRow['icon'])
              ? ''
              : dataKey.valid
              ? dataRow[dataKey.key]
                ? isArray(dataRow[dataKey.key])
                  ? dataRow[dataKey.key][0]
                  : dataRow[dataKey.key]
                : missingDataText
              : missingDataText
          }}

          <!-- remaining time in the report section -->
          <ng-container
            *ngIf="
              dataKey.key === 'status' &&
              this.dataType === 'reports' &&
              dataRow['remainingTime'].value > 0 &&
              (dataRow['status'] === 'Pret' ||
                dataRow['status'] === 'Ready' ||
                dataRow['status'] === 'Download in progress...' ||
                dataRow['status'] === 'Traitement en cours...')
            "
          >
            <div class="text-p3 disabled">
              <span> (Exp. </span>
              <span>
                {{ dataRow['remainingTime'].value }}
              </span>
              <span> {{ dataRow['remainingTime'].unit }}) </span>
            </div>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="scrolling-spinner" #spinner>
    <ad-spinner
      *ngIf="scrollingSpinnerIsVisible && infiniteScrollingMode"
    ></ad-spinner>
  </div>
</div>
