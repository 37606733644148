import {
  DriverView,
  EldLicenceType,
  ExemptionView,
  PermissionView,
} from '@backoffice/ng-backoffice-api-client';

import { DateUtil } from '@common/utils';
import { TranslateService } from '@ngx-translate/core';

import { convertToPhoneNumber } from '../../../../utils';

import { driverTableInterface } from '../../drivers/drivers';

const reasonMinLength = 4;

const EMPTY_ELEMENT_WITHOUT_ELD_LICENCE = '-';
/**
 * @description
 * Handle the driver data table.
 * @param {DriverView} driver - The driver
 * @param translateService
 * @returns {driverTableInterface} - The driver data table
 */
export const handleDriverDataTable = (
  driver: DriverView,
  translateService: TranslateService
): driverTableInterface => {
  return {
    id: [driver.id, { notShow: false }],
    updatedDate: [
      new DateUtil().fromISOToShortDateWithTime24(driver.updatedDate),
      {
        dataType: 'DATE',
        data: driver.updatedDate,
      },
    ],
    eldLicence: handleEldLicence(driver.eldLicence, translateService),
    name: handleDriverName(driver, translateService),
    phones: handleDriverPhones(driver),
    mails: handleDriverMails(driver),
    licenceNumber: handleDriverLicenceNumber(driver),
    licenceIssuingSubDivision:
      driver.licenceIssuingSubDivision || EMPTY_ELEMENT_WITHOUT_ELD_LICENCE,
    licenceIssuingCountry:
      driver.licenceIssuingCountry || EMPTY_ELEMENT_WITHOUT_ELD_LICENCE,
    homePort: handleDriverHomePort(driver),
    permissions: handlePermissions(driver.permissions, translateService),
    exemption: handleDriverExemption(driver.exemption, translateService),
    conformEld: [
      translateService.instant(driver.conformEld),
      {
        dataType: 'CELL_VALIDITY',
        data: driver.conformEld !== DriverView.ConformEldEnum.No,
      },
    ],
    validRow: driver.validRow,
    conflictedWithManager: [
      driver.userConflicts.length > 0
        ? translateService.instant('YES')
        : translateService.instant('NO'),
      {
        dataType: 'CELL_VALIDITY',
        data: driver.userConflicts.length === 0,
      },
    ],
  };
};

/**
 * @description
 * Translate the eld licence of the driver to the current language.
 * @param {EldLicenceType | null} eldLicence - The eld licence of the driver
 * @returns {string} - The translated eld licence
 */
const handleEldLicence = (
  eldLicence: EldLicenceType | null,
  translateService: TranslateService
): string => {
  return eldLicence
    ? eldLicence.toString().toUpperCase()
    : `${translateService.instant('DRIVERS_FORM.NONE')}`;
};

/**
 * @description
 * Translate the name of the driver to the current language.
 * @param {DriverView} driver - The driver
 * @returns {string} - The translated name
 **/
const handleDriverName = (
  driver: DriverView,
  translateService: TranslateService
): string => {
  const { lastName, firstName } = driver;
  return `
    ${lastName !== null ? lastName : ''} ${
    firstName !== null
      ? firstName
      : translateService.instant('DRIVERS_FORM.MISSING_DATA')
  }
  `;
};

/**
 * @description
 * Handle phone number of the driver according to the eld licence status.
 * @param {DriverView} driver - The driver
 * @returns {string} - The phone number of the driver according to the eld licence status
 **/
const handleDriverPhones = (driver: DriverView): string | null => {
  const { phones, eldLicence } = driver;
  // handle the case with user don't have any eld licence
  // the phone isn't mandatory in this case
  // so return the missing string `-`
  if (!eldLicence) {
    return phones && phones.length > 0
      ? phones.map((phone) => convertToPhoneNumber(phone)).join(', ')
      : EMPTY_ELEMENT_WITHOUT_ELD_LICENCE;
  }
  return phones && phones.length > 0
    ? phones.map((phone) => convertToPhoneNumber(phone)).join(', ')
    : null;
};

/**
 * @description
 * Handle mails of the driver according to the eld licence status.
 * @param {DriverView} driver - The driver
 * @returns {string} - The mails of the driver according to the eld licence status
 **/
const handleDriverMails = (driver: DriverView): string | null => {
  const { mails, eldLicence } = driver;
  // handle the case with user don't have any eld licence
  // the mails isn't mandatory in this case
  // so return the missing string `-`
  if (!eldLicence) {
    return mails && mails.length > 0
      ? mails.join(', ')
      : EMPTY_ELEMENT_WITHOUT_ELD_LICENCE;
  }
  return mails && mails.length > 0 ? mails.join(', ') : null;
};

/**
 * @description
 * Handle licence number of the driver according to the eld licence status.
 * @param {DriverView} driver - The driver
 * @returns {string} - The licence number of the driver according to the eld licence status
 **/
const handleDriverLicenceNumber = (driver: DriverView): string | null => {
  const { licenceNumber, eldLicence } = driver;
  // handle the case with user don't have any eld licence
  // the licence isn't mandatory in this case
  // so return the missing string `-`
  if (!eldLicence) {
    return licenceNumber || EMPTY_ELEMENT_WITHOUT_ELD_LICENCE;
  }
  return licenceNumber || null;
};

/**
 * @description
 * Handle home port of the driver according to the eld licence status.
 * @param {DriverView} driver - The driver
 * @returns {string} - The home port of the driver according to the eld licence status
 **/
const handleDriverHomePort = (driver: DriverView): string | null => {
  const { homePort, eldLicence } = driver;
  // handle the case with user don't have any eld licence
  // the home port isn't mandatory in this case
  // so return the missing string `-`
  if (!eldLicence) {
    return homePort?.name || EMPTY_ELEMENT_WITHOUT_ELD_LICENCE;
  }
  return homePort?.name || null;
};

/**
 * Handles the driver exemption based on the provided exemption information.
 * @param {ExemptionView} exemption The exemption information for the driver
 * @returns {string} the exemption status and reason if applicable
 */
const handleDriverExemption = (
  exemption: ExemptionView,
  translateService: TranslateService
): string => {
  // Extract exemption information from the provided object
  const { isExempted, reason } = exemption;

  // Check if the driver is exempted and if a reason is greater than `reasonMinLength` value
  if (isExempted && reason && reason.length >= reasonMinLength) {
    return `${translateService.instant('YES')} - ${reason}`;
  }

  // If the driver is not exempted, return a negative response
  if (!isExempted) return `${translateService.instant('NO')}`;

  return '';
};

/**
 * @description
 * Translate the permissions of the driver to the current language
 * @param {Array<PermissionView> | null} permissions - The permissions of the driver
 * @returns {string} - The translated permissions
 */
const translatePermissions = (
  permissions: Array<PermissionView> | null,
  translateService: TranslateService
): string => {
  const permissionsTranslation: Array<string> = [];

  permissions?.map((permission: PermissionView) => {
    permissionsTranslation.push(
      translateService.instant(`DRIVERS.${permission.permission.toUpperCase()}`)
    );
  });

  return permissionsTranslation ? permissionsTranslation.join(', ') : '';
};

/**
 * @description
 * Translate the permissions of the driver to the current language.
 * @param {Array<PermissionView> | null} permissions - The permissions of the driver
 * @returns {string} - The translated permissions
 */
const handlePermissions = (
  permissions: PermissionView[] | null,
  translateService: TranslateService
): string => {
  // handle the case where the driver has permissions equal to null
  if (!permissions) {
    return `${translateService.instant('DRIVERS_FORM.NONE')}`;
  }

  // handle the case where the driver has no permissions
  if (permissions.length === 0) {
    return translateService.instant('DRIVERS_FORM.NONE');
  }

  return translatePermissions(permissions, translateService);
};
