import { Injectable } from '@angular/core';
import { VehiclesService } from '@backoffice/ng-backoffice-api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { catchError, exhaustMap, map, of } from 'rxjs';

import { vehiclesAPIActions } from './vehicles.actions';
import { handleVehicleDataTable } from './vehicles.helper';

@Injectable()
export class VehiclesEffects {
  constructor(
    private actions$: Actions,
    private vehiclesService: VehiclesService,
    private translateService: TranslateService
  ) {}

  readonly getVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vehiclesAPIActions.vehiclesLoading),
      exhaustMap(() =>
        this.vehiclesService.vehiclesControllerFindAll().pipe(
          map((vehicles) => ({
            type: vehiclesAPIActions.vehiclesLoaded.type,
            vehiclesList: vehicles,
            vehiclesTableData: vehicles.map((vehicle) =>
              handleVehicleDataTable(vehicle, this.translateService)
            ),
          })),
          catchError((error) =>
            of({
              type: vehiclesAPIActions.vehiclesError.type,
              error: {
                message: 'Error loading vehicles',
                error,
              },
            })
          )
        )
      )
    )
  );
}
