import { Route } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';

import { NotFoundComponent } from './components/errors/not-found/not-found.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import {
  negatedPermissionsGuard,
  permissionsGuard,
} from './guards/permissions.guard';

export const appRoutes: Route[] = [
  {
    path: 'astus-pages-content',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [AuthGuard, permissionsGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'astus-pages-content',
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    canActivate: [negatedPermissionsGuard],
  },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
];
