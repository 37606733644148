import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';

@Directive({
  selector: '[adTableStickyColumns]',
})
export class TableStickyColumnsDirective implements OnChanges, AfterViewInit {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  isLoaded = false;

  @Input()
  adStickyIndex = 0;

  @Input()
  adStickyColumns = 0;

  @Input()
  adIsSticky = true;

  @Input()
  hasStatusColumn = true;

  ngAfterViewInit(): void {
    window.addEventListener('load', () => {
      this.isLoaded = true;

      this.applySticky();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.adIsSticky = changes['adIsSticky'].currentValue;

    this.applySticky();
  }

  applySticky() {
    if (this.adStickyIndex < this.adStickyColumns && this.adIsSticky) {
      // setTimeout is to make the calculate position "async", and wait for the changes to render before calculating
      setTimeout(() => {
        this.calculatePosition();
      }, 0);
    } else if (this.adStickyIndex === this.adStickyColumns - 1) {
      this.applyFixedBorer();
    }

    if (!this.adIsSticky) {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'position',
        'static'
      );
      this.renderer.setStyle(this.elementRef.nativeElement, 'left', 'auto');
    }
  }

  calculatePosition() {
    const currentElement = this.elementRef.nativeElement;
    const previousElements = this.hasStatusColumn
      ? (Array.from(currentElement.parentNode.children).slice(
          0,
          this.adStickyIndex + 1
        ) as HTMLElement[])
      : (Array.from(currentElement.parentNode.children).slice(
          1,
          this.adStickyIndex + 1
        ) as HTMLElement[]);

    const combinedWidth = previousElements.reduce((totalWidth, element) => {
      const elementWidth = element.getBoundingClientRect().width;
      return totalWidth + elementWidth;
    }, 0);
    this.renderer.setStyle(currentElement, 'position', 'sticky');
    this.renderer.setStyle(currentElement, 'left', `${combinedWidth}px`);
  }

  applyFixedBorer() {
    const currentElement = this.elementRef.nativeElement;
    this.renderer.setStyle(currentElement, 'border-right', '2px solid white');
  }
}
