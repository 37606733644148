import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ReportsDvirsInterface } from './reports-dvirs';
import { reportsDvirsFeatureKey } from './reports-dvirs.reducers';

export const reportsDvirsFeature = createFeatureSelector<ReportsDvirsInterface>(
  reportsDvirsFeatureKey
);

export const selectStoreReportsDvirsFeature = createSelector(
  reportsDvirsFeature,
  (data) => data
);

export const selectStoreDvirsPagination = createSelector(
  reportsDvirsFeature,
  (data) => data.pagination
);

export const selectStoreDvirsFeature = createSelector(
  reportsDvirsFeature,
  (data) => data.dvirs
);

export const selectStoreSearchBarFilterFeature = createSelector(
  reportsDvirsFeature,
  (data) => data.searchBar
);
