export enum TimeType {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_SEVEN_DAYS = 'last_seven_days',
  LAST_FOURTEEN_DAYS = 'last_fourteen_days',
  LAST_THIRTY_DAYS = 'last_thirty_days',
  LAST_MONTH = 'last_month',
  LAST_SIX_MONTHS = 'last_six_months',
  LAST_YEAR = 'last_year',
  THIS_YEAR = 'this_year',
  LAST_TWO_YEARS = 'last_two_years',
}

export function toTimeType(timeType: string): TimeType {
  if (timeType === 'today') {
    return TimeType.TODAY;
  }

  if (timeType === 'yesterday') {
    return TimeType.YESTERDAY;
  }

  if (timeType === 'last_seven_days') {
    return TimeType.LAST_SEVEN_DAYS;
  }

  if (timeType === 'last_fourteen_days') {
    return TimeType.LAST_FOURTEEN_DAYS;
  }

  if (timeType === 'last_thirty_days') {
    return TimeType.LAST_THIRTY_DAYS;
  }

  if (timeType === 'last_month') {
    return TimeType.LAST_MONTH;
  }

  if (timeType === 'last_six_months') {
    return TimeType.LAST_SIX_MONTHS;
  }

  if (timeType === 'last_year') {
    return TimeType.LAST_YEAR;
  }

  if (timeType === 'this_year') {
    return TimeType.THIS_YEAR;
  }

  return TimeType.LAST_TWO_YEARS;
}
