import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import {
  ConfigService,
  UserPermissionView,
} from '@backoffice/ng-backoffice-api-client';
import { catchError, map, of } from 'rxjs';

export const permissionsGuard: CanActivateFn = () => {
  const configService = inject(ConfigService);
  const router = inject(Router);
  return configService.configControllerGetPermissions().pipe(
    map((res) => {
      const hasPermissions = res.permissions.includes(
        UserPermissionView.PermissionsEnum.Eldmanager
      );
      if (!hasPermissions) {
        router.navigate(['/unauthorized']);
      }
      return hasPermissions;
    }),
    catchError(() => {
      router.navigate(['/unauthorized']);
      return of(false);
    })
  );
};

/**
 * Guard to block user from accessing the unauthorized pasge, if they have the permissions
 *
 * */
export const negatedPermissionsGuard: CanActivateFn = () => {
  const configService = inject(ConfigService);
  const router = inject(Router);

  return configService.configControllerGetPermissions().pipe(
    map((res) => {
      const hasPermissions = res.permissions.includes(
        UserPermissionView.PermissionsEnum.Eldmanager
      );
      if (hasPermissions) {
        router.navigate(['/astus-pages-content']);
      }
      return !hasPermissions;
    }),
    catchError(() => {
      return of(true);
    })
  );
};
