<div class="container" [ngClass]="{ unread: unread }">
  <div class="badge" [ngClass]="notificationType">
    <ad-icon
      *ngIf="glyph; else numContainer"
      [glyph]="glyph"
      color="neutral-50"
    ></ad-icon>
    <ng-template #numContainer>
      <p>{{ number }}</p>
    </ng-template>
  </div>
  <div class="content">
    <div class="text text-p2">
      <ng-content></ng-content>
    </div>
    <p class="time text-caption">{{ time }}</p>
  </div>
  <div class="unread-badge" [ngClass]="{ unread: unread }">
    <span></span>
  </div>
</div>
