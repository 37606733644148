import { Injectable } from '@angular/core';
import { UsersService } from '@backoffice/ng-backoffice-api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { catchError, concatMap, map, of, switchMap } from 'rxjs';

import { withFleetOwnerID } from '../../../../store/global.helper';
import { resourcesAPIActions } from '../../store';

import { usersAPIActions } from './users.actions';
import { handleUserDataTable } from './users.helper';

@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions,
    private usersService: UsersService,
    private translateService: TranslateService,
    private store: Store
  ) {}

  readonly getConflictedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersAPIActions.userLoading),
      switchMap(({ id }) =>
        withFleetOwnerID(this.store, (fleetOwnerID) =>
          this.usersService.usersControllerGetUser(id, fleetOwnerID).pipe(
            concatMap((conflictedUserView) => [
              {
                type: usersAPIActions.userLoaded.type,
                conflictedDriverView: conflictedUserView,
              },
              {
                type: resourcesAPIActions.updateInfoboxData.type,
                infoboxData: {
                  data: conflictedUserView,
                  type: 'user',
                },
              },
              {
                type: resourcesAPIActions.updateInfoboxHeader.type,
                infoboxHeader: {
                  title: `${conflictedUserView.firstName} ${conflictedUserView.lastName}`,
                  subtitle: this.translateService.instant('INFOBOX.USER'),
                  status:
                    !conflictedUserView.isELDManager ||
                    (conflictedUserView.isELDManager &&
                      Object.values(conflictedUserView.isConform).every(
                        (conformityRule) => !conformityRule
                      ))
                      ? 'valid'
                      : 'invalid',
                },
              },
              {
                type: usersAPIActions.usersSetCurrentID.type,
                usersOpenedEntry: conflictedUserView.index,
              },
            ]),
            catchError((error) =>
              of({
                type: usersAPIActions.usersError.type,
                error: error.error,
                message: 'Error loading user',
              })
            )
          )
        )
      )
    )
  );

  readonly getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersAPIActions.usersLoading),
      switchMap(() =>
        withFleetOwnerID(this.store, (fleetOwnerID) =>
          this.usersService.usersControllerGetAllUsers(fleetOwnerID).pipe(
            map((users) => ({
              type: usersAPIActions.usersLoaded.type,
              usersList: users,
              usersTableData: users.map((user) =>
                handleUserDataTable(user, this.translateService)
              ),
            })),
            catchError((error) =>
              of({
                type: usersAPIActions.usersError.type,
                error: error.error,
                message: 'Error loading users',
              })
            )
          )
        )
      )
    )
  );

  readonly createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersAPIActions.createUser),
      switchMap(({ user }) =>
        withFleetOwnerID(this.store, (fleetOwnerID) =>
          this.usersService.usersControllerCreateUser(fleetOwnerID, user).pipe(
            concatMap((userID: number) => [
              {
                type: usersAPIActions.usersLoading.type,
              },
              {
                type: usersAPIActions.usersErrorClear.type,
              },
              {
                type: resourcesAPIActions.resourcesCountersLoading.type,
              },
              {
                type: usersAPIActions.setUserUpdateID.type,
                id: userID,
              },
              {
                type: resourcesAPIActions.updateInfoboxHeader.type,
                infoboxHeader: {
                  title: `${user.firstName} ${user.lastName}`,
                  subtitle: this.translateService.instant('INFOBOX.USER'),
                  status: 'valid',
                },
              },
              {
                type: usersAPIActions.usersFormCreate.type,
                usersFormCreate: false,
              },
              {
                type: usersAPIActions.usersFormStatus.type,
                usersFormStatus: {
                  saving: false,
                  errorSaving: false,
                  hasTriedSaving: true,
                },
              },
            ]),
            catchError((error) => {
              return of(
                {
                  type: usersAPIActions.usersError.type,
                  error: error.error.cause,
                  message: error.error.msg,
                },
                {
                  type: usersAPIActions.usersFormStatus.type,
                  usersFormStatus: {
                    saving: false,
                    errorSaving: true,
                    hasTriedSaving: true,
                  },
                }
              );
            })
          )
        )
      )
    )
  );

  readonly updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersAPIActions.updateUser),
      switchMap(({ id, user, userFullName }) =>
        withFleetOwnerID(this.store, (fleetOwnerID) =>
          this.usersService
            .usersControllerUpdateUser(fleetOwnerID, id, user)
            .pipe(
              concatMap(() => [
                {
                  type: usersAPIActions.usersLoading.type,
                },
                {
                  type: usersAPIActions.usersErrorClear.type,
                },
                {
                  type: resourcesAPIActions.resourcesCountersLoading.type,
                },
                {
                  type: resourcesAPIActions.updateInfoboxHeader.type,
                  infoboxHeader: {
                    title: `${userFullName.firstName} ${userFullName.lastName}`,
                    subtitle: this.translateService.instant('INFOBOX.USER'),
                    status: 'valid',
                  },
                },
                {
                  type: usersAPIActions.usersFormStatus.type,
                  usersFormStatus: {
                    saving: false,
                    errorSaving: false,
                    hasTriedSaving: true,
                  },
                },
              ]),
              catchError((error) => {
                return of(
                  {
                    type: usersAPIActions.usersError.type,
                    error: error.error.cause,
                    message: error.error.msg,
                  },
                  {
                    type: usersAPIActions.usersFormStatus.type,
                    usersFormStatus: {
                      saving: false,
                      errorSaving: true,
                      hasTriedSaving: true,
                    },
                  }
                );
              })
            )
        )
      )
    )
  );
}
