<div
  class="chip"
  *ngIf="showChip"
  [title]="value"
  [ngClass]="{ hide: isClosing }"
>
  <p class="chip-text text-p3">{{ value }}</p>

  <div (click)="onChipClosing()" class="chip-icon">
    <ng-content #icon></ng-content>
  </div>
</div>
