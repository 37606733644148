import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { menuActions, showMenuFeature } from '../../store';

@Component({
  selector: 'astus-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  @Output() showMenuChange = new EventEmitter<boolean>();

  @Input() showMenu = false;

  topBarTitle: string | null = null;

  resourcesUrl: string[] = [
    '/astus-pages-content/resources/vehicles',
    '/astus-pages-content/resources/drivers',
  ];

  reportUrl: string[] = [
    '/astus-pages-content/reports/eld',
    '/astus-pages-content/reports/dvir',
  ];

  constructor(private router: Router, private store: Store) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.topBarTitle = this.getTopBarTitle(event.urlAfterRedirects);
      }
    });
    this.store.select(showMenuFeature).subscribe((showMenu) => {
      this.showMenu = showMenu;
    });
  }

  /**
   * Find and return the top bar title according the url.
   * @param {string} url url to get the tab name
   * @returns {string | null} the top bar title or null otherwise
   */
  getTopBarTitle(url: string): string | null {
    // check if current url = ressources
    if (this.resourcesUrl.includes(url)) {
      return 'TOPBAR.RESOURCES';
    }
    // check if current url = report
    if (this.reportUrl.includes(url)) {
      return 'TOPBAR.REPORTS';
    }
    return null;
  }

  /**
   * @description
   * Toggle the menu display state and emit the new state
   */
  toggleMenu(showMenu: boolean) {
    this.store.dispatch(menuActions.toggleMenu({ showMenu }));
  }
}
