export enum IconAtomWeatherEnum {
  'cloudy' = 'cloudy',
  'mostly-cloudy' = 'mostly-cloudy',
  'partly-sunny' = 'partly-sunny',
  'rain-snow' = 'rain-snow',
  'raining' = 'raining',
  'snow' = 'snow',
  'sunny' = 'sunny',
}

export enum IconAtomGlyphEnum {
  'alert' = 'alert',
  'analytics' = 'analytics',
  'bus_add' = 'bus_add',
  'bus-filled' = 'bus-filled',
  'bus' = 'bus',
  'caret_down' = 'caret_down',
  'caret_left' = 'caret_left',
  'caret_right' = 'caret_right',
  'caret_up' = 'caret_up',
  'check' = 'check',
  'chevron_down' = 'chevron_down',
  'chevron_left' = 'chevron_left',
  'chevron_right' = 'chevron_right',
  'chevron_up' = 'chevron_up',
  'circuit' = 'circuit',
  'close' = 'close',
  'contract' = 'contract',
  'conversion_path' = 'conversion_path',
  'copy' = 'copy',
  'copy-filled' = 'copy-filled',
  'data_transfer' = 'data_transfer',
  'download' = 'download',
  'drag-handle' = 'drag-handle',
  'email-stroke' = 'email-stroke',
  'email' = 'email',
  'file' = 'file',
  'fuel' = 'fuel',
  'fullscreen-exit' = 'fullscreen-exit',
  'fullscreen' = 'fullscreen',
  'eye_off' = 'eye_off',
  'history' = 'history',
  'info-i' = 'info-i',
  'info' = 'info',
  'location-empty' = 'location-empty',
  'location-filled' = 'location-filled',
  'location' = 'location',
  'lock' = 'lock',
  'logout' = 'logout',
  'maintenance-filled' = 'maintenance-filled',
  'map_options' = 'map_options',
  'menu' = 'menu',
  'minus' = 'minus',
  'more-vertical' = 'more-vertical',
  'navigation-filled' = 'navigation-filled',
  'notification' = 'notification',
  'oil' = 'oil',
  'open_new' = 'open_new',
  'page' = 'page',
  'phone' = 'phone',
  'play_realtime' = 'play_realtime',
  'plus' = 'plus',
  'profile-add' = 'profile-add',
  'profile-filled' = 'profile-filled',
  'profile' = 'profile',
  'pulse' = 'pulse',
  'rds-filled' = 'rds-filled',
  'rds' = 'rds',
  'reset' = 'reset',
  'resources' = 'resources',
  'route' = 'route',
  'schedule' = 'schedule',
  'search' = 'search',
  'seat' = 'seat',
  'settings' = 'settings',
  'share' = 'share',
  'sort' = 'sort',
  'support' = 'support',
  'time' = 'time',
  'time-range' = 'time-range',
  'trash' = 'trash',
  'unavailable-filled' = 'unavailable-filled',
  'upload-filled' = 'upload-filled',
  'users' = 'users',
  'users-filled' = 'users-filled',
}

export type IconAtomWeatherType = keyof typeof IconAtomWeatherEnum;

export type IconAtomGlyphType = keyof typeof IconAtomGlyphEnum;

export type IconAtomType =
  | keyof typeof IconAtomGlyphEnum
  | keyof typeof IconAtomWeatherEnum;
