import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CalendarModule } from 'primeng/calendar';

import { ModalAtom } from './atoms';
import { AvatarIconAtom } from './atoms/avatar-icon/avatar-icon.component';
import { AvatarTextAtom } from './atoms/avatar-text/avatar-text.component';
import { BadgeAtom } from './atoms/badge/badge.component';
import { IconAtom } from './atoms/icon/icon.component';
import { SpinnerAtom } from './atoms/spinner/spinner.component';
import { AdInputDirective } from './directives/input-field/input/ad-input.directive';
import { ProgressBarNavigationDirective } from './directives/progress-bar-navigation/progress-bar-navigation.directive';
import { SkeletonDirective } from './directives/skeleton/skeleton.directive';
import { StickyColumnsDirective } from './directives/sticky-table/sticky-table.directive';
import { TableStickyColumnsDirective } from './directives/table-sticky-columns/table-sticky-columns.directive';
import { AlertMolecule } from './molecules/alert/alert.component';
import { ButtonMolecule } from './molecules/button/button.component';
import { CardInfoMolecule } from './molecules/card-info/card-info.component';
import { CheckboxMolecule } from './molecules/checkbox/checkbox.component';
import { ChipMolecule } from './molecules/chip/chip.component';
import { ChipSearchMolecule } from './molecules/chip-search/chip-search.component';
import { FabMolecule } from './molecules/fab/fab.component';
import { FabExtendedMolecule } from './molecules/fab-extended/fab-extended.component';
import { FabGroupedMolecule } from './molecules/fab-grouped/fab-grouped.component';
import { InputMolecule } from './molecules/input-field/input/input.component';
import { LabelMolecule } from './molecules/input-field/label/label.component';
import { LoadingMolecule } from './molecules/loading/loading.component';
import { MapControlTooltipMolecule } from './molecules/map-control-tooltip/map-control-tooltip.component';
import { NavigationDrawerHeaderMolecule } from './molecules/navigation-drawer-header/navigation-drawer-header.component';
import { NavigationDrawerItemMolecule } from './molecules/navigation-drawer-item/navigation-drawer-item.component';
import { NavigationDrawerPanelMolecule } from './molecules/navigation-drawer-panel/navigation-drawer-panel.component';
import { NotificationCardMolecule } from './molecules/notification-card/notification-card.component';
import { PopoverMolecule } from './molecules/popover/popover.molecule';
import { ProgressBarLineMolecule } from './molecules/progress-bar/progress-bar-line/progress-bar-line.molecule.component';
import { ProgressBarMolecule } from './molecules/progress-bar/progress-bar-navigation/progress-bar-navigation.component';
import { RadioMolecule } from './molecules/radio/radio.component';
import { RefresherMolecule } from './molecules/refresher/refresher.component';
import { SearchMolecule } from './molecules/search/search.component';
import { SeparatorMolecule } from './molecules/separator/separator.component';
import { SkeletonMolecule } from './molecules/skeleton/skeleton.component';
import { SnackbarMolecule } from './molecules/snackbar/snackbar.component';
import { StatusOrganism } from './molecules/status/status.component';
import { SwitchMolecule } from './molecules/switch/switch.component';
import { TooltipMolecule } from './molecules/tooltip/tooltip.component';
import { WeekdayPickerMolecule } from './molecules/weekday-picker/weekday-picker.component';
import { DropdownOrganism } from './organisms';
import { AlertCardOrganism } from './organisms/alert-card/alert-card.component';
import { CalendarOrganism } from './organisms/calendar/calendar.component';
import { CardOrganism } from './organisms/card/card.component';
import { CardMinimalOrganism } from './organisms/card-minimal/card-minimal.component';
import { DatePickerOrganism } from './organisms/date-picker/date-picker.component';
import { DialogOrganism } from './organisms/dialog/dialog.component';
import { NavigationDrawerOrganism } from './organisms/navigation-drawer/navigation-drawer.component';
import { ProgressInfoOrganism } from './organisms/progress-info/progress-info.component';
import { SearchFilterOrganism } from './organisms/search-filter/search-filter.component';
import { TableOrganism } from './organisms/table/table.component';
import { TableEmptyStateOrganism } from './organisms/table-empty-state/table-empty-state.component';
import { UploadOrganism } from './organisms/upload/upload.component';
import { WidgetOrganism } from './organisms/widget/widget.component';
import { WidgetTabOrganism } from './organisms/widget-tab/widget-tab.component';
import { SearchBarEldTemplate, SearchBarTemplate } from './templates';

@NgModule({
  imports: [CommonModule, CalendarModule, FormsModule],
  declarations: [
    SwitchMolecule,
    AvatarIconAtom,
    AvatarTextAtom,
    AlertMolecule,
    ButtonMolecule,
    CardInfoMolecule,
    CardOrganism,
    CardMinimalOrganism,
    CheckboxMolecule,
    FabMolecule,
    FabExtendedMolecule,
    FabGroupedMolecule,
    StatusOrganism,
    TableOrganism,
    AlertCardOrganism,
    WidgetOrganism,
    WidgetTabOrganism,
    ProgressBarMolecule,
    RadioMolecule,
    AdInputDirective,
    LabelMolecule,
    InputMolecule,
    ProgressBarNavigationDirective,
    UploadOrganism,
    TableStickyColumnsDirective,
    ProgressInfoOrganism,
    TableEmptyStateOrganism,
    SpinnerAtom,
    LoadingMolecule,
    RefresherMolecule,
    SkeletonMolecule,
    SkeletonDirective,
    TooltipMolecule,
    PopoverMolecule,
    MapControlTooltipMolecule,
    IconAtom,
    ProgressBarLineMolecule,
    DropdownOrganism,
    ChipSearchMolecule,
    SearchMolecule,
    CalendarOrganism,
    ChipMolecule,
    BadgeAtom,
    SearchBarTemplate,
    SearchFilterOrganism,
    ModalAtom,
    NotificationCardMolecule,
    NavigationDrawerItemMolecule,
    NavigationDrawerOrganism,
    SeparatorMolecule,
    NavigationDrawerHeaderMolecule,
    SearchBarEldTemplate,
    NavigationDrawerPanelMolecule,
    SnackbarMolecule,
    DatePickerOrganism,
    WeekdayPickerMolecule,
    StickyColumnsDirective,
    DialogOrganism,
  ],
  exports: [
    SwitchMolecule,
    AvatarIconAtom,
    AvatarTextAtom,
    AlertMolecule,
    ButtonMolecule,
    CardInfoMolecule,
    CardOrganism,
    CardMinimalOrganism,
    CheckboxMolecule,
    FabMolecule,
    FabExtendedMolecule,
    FabGroupedMolecule,
    StatusOrganism,
    TableOrganism,
    AlertCardOrganism,
    WidgetOrganism,
    WidgetTabOrganism,
    ProgressBarMolecule,
    RadioMolecule,
    AdInputDirective,
    LabelMolecule,
    InputMolecule,
    ProgressBarNavigationDirective,
    UploadOrganism,
    TableStickyColumnsDirective,
    ProgressInfoOrganism,
    TableEmptyStateOrganism,
    SpinnerAtom,
    LoadingMolecule,
    RefresherMolecule,
    SkeletonMolecule,
    SkeletonDirective,
    TooltipMolecule,
    PopoverMolecule,
    MapControlTooltipMolecule,
    IconAtom,
    ProgressBarLineMolecule,
    DropdownOrganism,
    ChipSearchMolecule,
    SearchMolecule,
    CalendarOrganism,
    ChipMolecule,
    BadgeAtom,
    SearchBarTemplate,
    SearchFilterOrganism,
    ModalAtom,
    NotificationCardMolecule,
    NavigationDrawerItemMolecule,
    SeparatorMolecule,
    NavigationDrawerHeaderMolecule,
    SearchBarEldTemplate,
    NavigationDrawerOrganism,
    NavigationDrawerPanelMolecule,
    SnackbarMolecule,
    DatePickerOrganism,
    WeekdayPickerMolecule,
    StickyColumnsDirective,
    DialogOrganism,
  ],
})
export class CommonNgDesignSystemModule {}
