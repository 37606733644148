import { createReducer, on } from '@ngrx/store';

import { resourcesReducerInterface } from './resources';
import { resourcesAPIActions } from './resources.actions';

export const resourcesFeatureKey = 'resourcesFeature';

export const resourcesInitialState: resourcesReducerInterface = {
  fleetOwnerID: -1,
  infoboxData: null,
  infoboxHeader: null,
  resourcesCounters: {
    loaded: false,
    vehicles: {
      totals: 0,
      problems: 0,
    },
    drivers: {
      totals: 0,
      problems: 0,
    },
    users: {
      totals: 0,
      problems: 0,
    },
  },
};

export const resourcesFeatureReducer = createReducer<resourcesReducerInterface>(
  resourcesInitialState,
  on(resourcesAPIActions.updateInfoboxData, (_state, { infoboxData }) => ({
    ..._state,
    infoboxData,
  })),
  on(
    resourcesAPIActions.resourcesCountersLoaded,
    (_state, { resourcesCounters }) => ({
      ..._state,
      resourcesCounters,
    })
  ),
  on(resourcesAPIActions.updateInfoboxHeader, (_state, { infoboxHeader }) => ({
    ..._state,
    infoboxHeader,
  })),
  on(resourcesAPIActions.clearInfoboxHeader, (_state) => ({
    ..._state,
    infoboxHeader: null,
  })),
  on(resourcesAPIActions.fleetOwnerIDLoaded, (_state, { fleetOwnerID }) => ({
    ..._state,
    fleetOwnerID,
  }))
);
