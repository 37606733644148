import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { colors } from '../../common-ng-design-system.colors';

import { IconAtomType, IconAtomWeatherEnum } from './icon.enum';

@Component({
  selector: 'ad-icon',
  template: `
    <svg
      [ngClass]="getSVGClass()"
      [class]="svgClass"
      [style]="
        customSize
          ? {
              '--ad-icon-svg-width': customSize,
              '--ad-icon-svg-height': customSize
            }
          : {}
      "
      [attr.width]="customSize || defaultWidth"
      [attr.height]="customSize || defaultWidth"
      [attr.viewBox]="
        '0 0 ' +
        (customSize || defaultWidth) +
        ' ' +
        (customSize || defaultWidth)
      "
    >
      <use [attr.href]="glyphUrl"></use>
    </svg>
    <ng-content select="[iconContent]"></ng-content>
  `,
  styleUrls: ['./icon.component.scss'],
})
export class IconAtom implements OnInit {
  _color: keyof typeof colors | undefined = undefined;

  @Input()
  glyph: IconAtomType = 'bus';

  @Input()
  svgClass = '';

  @HostBinding('style.--ad-icon-fill-color')
  get fillColor(): string {
    return this._color ? `rgb(var(--color-${this._color}))` : '';
  }

  @Input()
  public get color(): keyof typeof colors | undefined {
    return this._color;
  }
  public set color(val: keyof typeof colors | undefined) {
    this._color = val;
  }

  @Input()
  customSize: number | null = null;

  @Input()
  defaultWidth = 24;

  @Input()
  stroke: keyof typeof colors | undefined = undefined;

  // Some icons aren't pathed out in a way that fill would work by default,
  // so this array is used to force a default shift on certain icons
  useColorAsStrokeFor: IconAtomType[] = ['check', 'email-stroke'];

  ngOnInit(): void {
    this.defaultWidth =
      this.glyph in IconAtomWeatherEnum ? 40 : this.defaultWidth;
  }

  getSVGClass() {
    return this.useColorAsStrokeFor.includes(this.glyph)
      ? 'alternate-colors'
      : '';
  }

  public get glyphUrl(): string {
    const url = `/icons/icons.svg#${this.glyph}`;
    return url;
  }
}
