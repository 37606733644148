/**
 * Back-office API
 * In charge of providing data to the back-office frontend apps
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
  HttpContext,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { DataTransferReqDto } from '../model/dataTransferReqDto';
// @ts-ignore
import { ExportView } from '../model/exportView';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(
    httpParams: HttpParams,
    value: any,
    key?: string
  ): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(
    httpParams: HttpParams,
    value?: any,
    key?: string
  ): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(
          (elem) =>
            (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key))
        );
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(
            key,
            (value as Date).toISOString().substr(0, 10)
          );
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(
          (k) =>
            (httpParams = this.addToHttpParamsRecursive(
              httpParams,
              value[k],
              key != null ? `${key}.${k}` : k
            ))
        );
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Export dvir by id.
   * @param id
   * @param vehicleId
   * @param format
   * @param language
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportControllerFindDvirById(
    id: string,
    vehicleId: number,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    language?: 'en' | 'fr',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ExportView>;
  public exportControllerFindDvirById(
    id: string,
    vehicleId: number,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    language?: 'en' | 'fr',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ExportView>>;
  public exportControllerFindDvirById(
    id: string,
    vehicleId: number,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    language?: 'en' | 'fr',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ExportView>>;
  public exportControllerFindDvirById(
    id: string,
    vehicleId: number,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    language?: 'en' | 'fr',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling exportControllerFindDvirById.'
      );
    }
    if (vehicleId === null || vehicleId === undefined) {
      throw new Error(
        'Required parameter vehicleId was null or undefined when calling exportControllerFindDvirById.'
      );
    }
    if (format === null || format === undefined) {
      throw new Error(
        'Required parameter format was null or undefined when calling exportControllerFindDvirById.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (vehicleId !== undefined && vehicleId !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>vehicleId,
        'vehicleId'
      );
    }
    if (language !== undefined && language !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>language,
        'language'
      );
    }
    if (format !== undefined && format !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>format,
        'format'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/export/dvirs/${this.configuration.encodeParam({
      name: 'id',
      value: id,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'string',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<ExportView>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Export all dvirs.
   * @param page
   * @param accept
   * @param after
   * @param before
   * @param dvirResourceType
   * @param language
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportControllerFindDvirs(
    page: number,
    accept:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    after?: string,
    before?: string,
    dvirResourceType?: 'all' | 'driver' | 'vehicle',
    language?: 'en' | 'fr',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ExportView>;
  public exportControllerFindDvirs(
    page: number,
    accept:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    after?: string,
    before?: string,
    dvirResourceType?: 'all' | 'driver' | 'vehicle',
    language?: 'en' | 'fr',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ExportView>>;
  public exportControllerFindDvirs(
    page: number,
    accept:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    after?: string,
    before?: string,
    dvirResourceType?: 'all' | 'driver' | 'vehicle',
    language?: 'en' | 'fr',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ExportView>>;
  public exportControllerFindDvirs(
    page: number,
    accept:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    after?: string,
    before?: string,
    dvirResourceType?: 'all' | 'driver' | 'vehicle',
    language?: 'en' | 'fr',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (page === null || page === undefined) {
      throw new Error(
        'Required parameter page was null or undefined when calling exportControllerFindDvirs.'
      );
    }
    if (accept === null || accept === undefined) {
      throw new Error(
        'Required parameter accept was null or undefined when calling exportControllerFindDvirs.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (after !== undefined && after !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>after,
        'after'
      );
    }
    if (before !== undefined && before !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>before,
        'before'
      );
    }
    if (dvirResourceType !== undefined && dvirResourceType !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>dvirResourceType,
        'dvirResourceType'
      );
    }
    if (language !== undefined && language !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>language,
        'language'
      );
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>page,
        'page'
      );
    }
    if (accept !== undefined && accept !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>accept,
        'accept'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/export/dvirs`;
    return this.httpClient.request<ExportView>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Export eld by id.
   * @param driverId
   * @param day Day of the exported file.
   * @param format Format of the exported file.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportControllerFindEldById(
    driverId: number,
    day: string,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ExportView>;
  public exportControllerFindEldById(
    driverId: number,
    day: string,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ExportView>>;
  public exportControllerFindEldById(
    driverId: number,
    day: string,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ExportView>>;
  public exportControllerFindEldById(
    driverId: number,
    day: string,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (driverId === null || driverId === undefined) {
      throw new Error(
        'Required parameter driverId was null or undefined when calling exportControllerFindEldById.'
      );
    }
    if (day === null || day === undefined) {
      throw new Error(
        'Required parameter day was null or undefined when calling exportControllerFindEldById.'
      );
    }
    if (format === null || format === undefined) {
      throw new Error(
        'Required parameter format was null or undefined when calling exportControllerFindEldById.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (day !== undefined && day !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>day,
        'day'
      );
    }
    if (format !== undefined && format !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>format,
        'format'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/export/elds/${this.configuration.encodeParam({
      name: 'driverId',
      value: driverId,
      in: 'path',
      style: 'simple',
      explode: false,
      dataType: 'number',
      dataFormat: undefined,
    })}`;
    return this.httpClient.request<ExportView>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Export eld by installationId.
   * @param installationId
   * @param day Day of the exported file.
   * @param format Format of the exported file.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportControllerFindEldByInstallationID(
    installationId: string,
    day: string,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ExportView>;
  public exportControllerFindEldByInstallationID(
    installationId: string,
    day: string,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ExportView>>;
  public exportControllerFindEldByInstallationID(
    installationId: string,
    day: string,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ExportView>>;
  public exportControllerFindEldByInstallationID(
    installationId: string,
    day: string,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (installationId === null || installationId === undefined) {
      throw new Error(
        'Required parameter installationId was null or undefined when calling exportControllerFindEldByInstallationID.'
      );
    }
    if (day === null || day === undefined) {
      throw new Error(
        'Required parameter day was null or undefined when calling exportControllerFindEldByInstallationID.'
      );
    }
    if (format === null || format === undefined) {
      throw new Error(
        'Required parameter format was null or undefined when calling exportControllerFindEldByInstallationID.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (day !== undefined && day !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>day,
        'day'
      );
    }
    if (format !== undefined && format !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>format,
        'format'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/export/elds/unidentified/${this.configuration.encodeParam(
      {
        name: 'installationId',
        value: installationId,
        in: 'path',
        style: 'simple',
        explode: false,
        dataType: 'string',
        dataFormat: undefined,
      }
    )}`;
    return this.httpClient.request<ExportView>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Export all elds.
   * @param filename Name of the exported file.
   * @param format Format of the exported file.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportControllerFindElds(
    filename: string,
    format?: 'PDF' | 'CSV',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ExportView>;
  public exportControllerFindElds(
    filename: string,
    format?: 'PDF' | 'CSV',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ExportView>>;
  public exportControllerFindElds(
    filename: string,
    format?: 'PDF' | 'CSV',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ExportView>>;
  public exportControllerFindElds(
    filename: string,
    format?: 'PDF' | 'CSV',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (filename === null || filename === undefined) {
      throw new Error(
        'Required parameter filename was null or undefined when calling exportControllerFindElds.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (filename !== undefined && filename !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>filename,
        'filename'
      );
    }

    let localVarHeaders = this.defaultHeaders;
    if (format !== undefined && format !== null) {
      localVarHeaders = localVarHeaders.set('format', String(format));
    }

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/export/elds`;
    return this.httpClient.request<ExportView>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get all eld reports by driver.
   * @param start
   * @param end
   * @param page
   * @param resourceId Driver id or Vehicle id
   * @param format Format of the exported file.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportControllerGetEldReportsByDriver(
    start: string,
    end: string,
    page: number,
    resourceId: number,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ExportView>;
  public exportControllerGetEldReportsByDriver(
    start: string,
    end: string,
    page: number,
    resourceId: number,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ExportView>>;
  public exportControllerGetEldReportsByDriver(
    start: string,
    end: string,
    page: number,
    resourceId: number,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ExportView>>;
  public exportControllerGetEldReportsByDriver(
    start: string,
    end: string,
    page: number,
    resourceId: number,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (start === null || start === undefined) {
      throw new Error(
        'Required parameter start was null or undefined when calling exportControllerGetEldReportsByDriver.'
      );
    }
    if (end === null || end === undefined) {
      throw new Error(
        'Required parameter end was null or undefined when calling exportControllerGetEldReportsByDriver.'
      );
    }
    if (page === null || page === undefined) {
      throw new Error(
        'Required parameter page was null or undefined when calling exportControllerGetEldReportsByDriver.'
      );
    }
    if (resourceId === null || resourceId === undefined) {
      throw new Error(
        'Required parameter resourceId was null or undefined when calling exportControllerGetEldReportsByDriver.'
      );
    }
    if (format === null || format === undefined) {
      throw new Error(
        'Required parameter format was null or undefined when calling exportControllerGetEldReportsByDriver.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (start !== undefined && start !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>start,
        'start'
      );
    }
    if (end !== undefined && end !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>end,
        'end'
      );
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>page,
        'page'
      );
    }
    if (resourceId !== undefined && resourceId !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>resourceId,
        'resourceId'
      );
    }
    if (format !== undefined && format !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>format,
        'format'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/export/eld/driver`;
    return this.httpClient.request<ExportView>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get all eld reports by vehicle.
   * @param start
   * @param end
   * @param page
   * @param resourceId Driver id or Vehicle id
   * @param format Format of the exported file.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportControllerGetEldReportsByVehicle(
    start: string,
    end: string,
    page: number,
    resourceId: number,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ExportView>;
  public exportControllerGetEldReportsByVehicle(
    start: string,
    end: string,
    page: number,
    resourceId: number,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ExportView>>;
  public exportControllerGetEldReportsByVehicle(
    start: string,
    end: string,
    page: number,
    resourceId: number,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ExportView>>;
  public exportControllerGetEldReportsByVehicle(
    start: string,
    end: string,
    page: number,
    resourceId: number,
    format:
      | 'application/json'
      | 'text/csv'
      | 'application/pdf'
      | 'application/zip',
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    if (start === null || start === undefined) {
      throw new Error(
        'Required parameter start was null or undefined when calling exportControllerGetEldReportsByVehicle.'
      );
    }
    if (end === null || end === undefined) {
      throw new Error(
        'Required parameter end was null or undefined when calling exportControllerGetEldReportsByVehicle.'
      );
    }
    if (page === null || page === undefined) {
      throw new Error(
        'Required parameter page was null or undefined when calling exportControllerGetEldReportsByVehicle.'
      );
    }
    if (resourceId === null || resourceId === undefined) {
      throw new Error(
        'Required parameter resourceId was null or undefined when calling exportControllerGetEldReportsByVehicle.'
      );
    }
    if (format === null || format === undefined) {
      throw new Error(
        'Required parameter format was null or undefined when calling exportControllerGetEldReportsByVehicle.'
      );
    }

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (start !== undefined && start !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>start,
        'start'
      );
    }
    if (end !== undefined && end !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>end,
        'end'
      );
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>page,
        'page'
      );
    }
    if (resourceId !== undefined && resourceId !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>resourceId,
        'resourceId'
      );
    }
    if (format !== undefined && format !== null) {
      localVarQueryParameters = this.addToHttpParams(
        localVarQueryParameters,
        <any>format,
        'format'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/export/eld/vehicle`;
    return this.httpClient.request<ExportView>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Send data transfers.
   * @param dataTransferReqDto
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportControllerSendDataTransfers(
    dataTransferReqDto: DataTransferReqDto,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext }
  ): Observable<any>;
  public exportControllerSendDataTransfers(
    dataTransferReqDto: DataTransferReqDto,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext }
  ): Observable<HttpResponse<any>>;
  public exportControllerSendDataTransfers(
    dataTransferReqDto: DataTransferReqDto,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext }
  ): Observable<HttpEvent<any>>;
  public exportControllerSendDataTransfers(
    dataTransferReqDto: DataTransferReqDto,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext }
  ): Observable<any> {
    if (dataTransferReqDto === null || dataTransferReqDto === undefined) {
      throw new Error(
        'Required parameter dataTransferReqDto was null or undefined when calling exportControllerSendDataTransfers.'
      );
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (bearer) required
    localVarCredential = this.configuration.lookupCredential('bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set(
        'Authorization',
        'Bearer ' + localVarCredential
      );
    }

    let localVarHttpHeaderAcceptSelected: string | undefined =
      options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected =
        this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Accept',
        localVarHttpHeaderAcceptSelected
      );
    }

    let localVarHttpContext: HttpContext | undefined =
      options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set(
        'Content-Type',
        httpContentTypeSelected
      );
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (
        this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)
      ) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/export/dataTransfer`;
    return this.httpClient.request<any>(
      'post',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: dataTransferReqDto,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
