import { createReducer, on } from '@ngrx/store';

import { usersReducerInterface } from './users';
import { usersAPIActions } from './users.actions';

export const usersFeatureKey = 'usersFeature';

export const usersInitialState: usersReducerInterface = {
  loaded: false,
  usersList: [],
  usersTableData: [],
  usersFormCreate: false,
  usersFormStatus: {
    hasTriedSaving: false,
    saving: false,
    errorSaving: false,
  },
  usersFormValidation: 'new',
  usersOpenedEntry: null,
  error: null,
  isModalOpen: false,
  userUpdateID: -1,
};

export const usersFeatureReducer = createReducer(
  usersInitialState,
  on(usersAPIActions.usersLoaded, (_state, { usersList, usersTableData }) => ({
    ..._state,
    loaded: true,
    usersList,
    usersTableData,
  })),
  on(usersAPIActions.usersFormCreate, (_state, { usersFormCreate }) => ({
    ..._state,
    usersFormCreate,
  })),
  on(usersAPIActions.usersFormStatus, (_state, { usersFormStatus }) => ({
    ..._state,
    usersFormStatus,
  })),
  on(usersAPIActions.usersAddSuccess, (_state, { user, userTable }) => ({
    ..._state,
    usersFormCreate: false,
    usersList: [..._state.usersList, user],
    usersTableData: [..._state.usersTableData, userTable],
    error: null,
  })),
  on(
    usersAPIActions.usersUpdateSuccess,
    (_state, { user, userTable, index }) => ({
      ..._state,
      usersList: _state.usersList.map((item, listIndex) => {
        if (item.id === user.id) {
          index = listIndex;
          return user;
        }
        return item;
      }),
      usersTableData: _state.usersTableData.map((item, tableIndex) => {
        if (tableIndex === index) {
          return userTable;
        }
        return item;
      }),
      error: null,
    })
  ),
  on(usersAPIActions.usersFormValidation, (_state, { validation }) => ({
    ..._state,
    usersFormValidation: validation,
  })),
  on(usersAPIActions.usersError, (_state, { error, message }) => ({
    ..._state,
    error: {
      error,
      message,
    },
  })),
  on(usersAPIActions.usersErrorClear, (_state) => ({
    ..._state,
    error: null,
  })),
  on(usersAPIActions.usersSetCurrentID, (_state, { usersOpenedEntry }) => ({
    ..._state,
    usersOpenedEntry,
    usersFormStatus: {
      hasTriedSaving: false,
      saving: false,
      errorSaving: false,
    },
  })),
  on(usersAPIActions.usersUnsetCurrentID, (_state) => ({
    ..._state,
    usersOpenedEntry: null,
  })),
  on(usersAPIActions.usersTogglingPermissionModal, (_state, { isOpen }) => ({
    ..._state,
    isModalOpen: isOpen,
  })),
  on(usersAPIActions.userLoaded, (_state, { conflictedUserView }) => ({
    ..._state,
    conflictedUserView,
  })),
  on(usersAPIActions.setUserUpdateID, (_state, { id }) => ({
    ..._state,
    userUpdateID: id,
  }))
);
