import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import classNames from 'classnames';

import { colors } from '../../common-ng-design-system.colors';

import {
  tableHeaderInterface,
  tableMetaDataTypeInterface,
  tablePropsInterface,
  tablePropsType,
  tableRowEventType,
  tableRowIconType,
  tableRowIndexType,
} from './table.d';
import { compareMetaData, tableMetaDataType } from './table.helper';

const maxDataToView = 30;
@Component({
  selector: 'ad-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableOrganism
  implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
  ready = false;
  redrawHeader = false;
  loadedDataIndex = maxDataToView;

  loadEnd() {
    this.redrawHeader = true;
  }

  @Input()
  data?: tablePropsInterface[];

  dataToView: tablePropsInterface[] = [];

  @Input()
  headers!: tableHeaderInterface[];

  @Input()
  sorting = false;

  @Input()
  stickyColumns = 3;

  @Input()
  missingDataText = '-';

  @Input()
  iconButtons?: tableRowIconType[];

  @Input()
  exceptPropertyValidation: Array<tablePropsType> = [];

  @Input()
  showColumn = true;

  @Input()
  forceActiveRow: tableRowIndexType | null = null;

  @Input()
  infiniteScrollingMode = false;

  @Input()
  dataType = '';

  hasStatusColumn = false;
  loadOnScroll = true;

  @Output()
  tableRowSelection = new EventEmitter<tableRowEventType>();

  @Output()
  iconEvent = new EventEmitter<tablePropsInterface>();

  @Output()
  scrollingAtBottom = new EventEmitter<void>();

  dataKeys!: {
    key: string;
    valid: boolean;
  }[];
  activeRowIndex: tableRowIndexType | null = this.forceActiveRow;
  hoverRowIndex?: number;
  isMobile = false;

  statusHeader: tableHeaderInterface = {
    dataKey: 'validRow',
    isVisible: false,
    sorted: 'default',
    label: '',
  };

  dataCopy?: tablePropsInterface[];

  scrollingSpinnerIsVisible = false;

  @ViewChild('table') table!: ElementRef<HTMLDivElement>;

  @ViewChild('spinner') spinnerElement!: ElementRef<HTMLDivElement>;

  tableEndObserver!: IntersectionObserver;

  ngOnInit(): void {
    this.isMobile = window.innerWidth < 1200;

    this.initScrollEndIntersectionObserver();

    this.activeRowIndex = this.forceActiveRow;

    this.hasStatusColumn = this.dataType !== 'reports';

    if (this.data) {
      this.dataToView = [...this.data];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      if (this.loadOnScroll && !this.infiniteScrollingMode) {
        this.loadedDataIndex =
          this.dataToView.length > 0 ? this.loadedDataIndex : maxDataToView;

        this.dataToView = [
          ...(this.data?.slice(0, this.loadedDataIndex) ?? []),
        ];
      } else {
        this.dataToView = [...(this.data ?? [])];
      }
    }

    // add the status header to the existing headers
    if (changes['headers'] && changes['headers'].currentValue) {
      this.headers.push(this.statusHeader);
    }
    this.dataKeys = this.headers
      .filter((header) => header.dataKey !== this.statusHeader.dataKey)
      .map((header) => {
        if (this.data?.some((item) => header.dataKey in item)) {
          return { key: header.dataKey, valid: true };
        }
        return { key: header.dataKey, valid: false };
      });

    if (this.data && this.headers) {
      this.ready = true;
    }
    if (this.forceActiveRow !== null) {
      this.activeRowIndex = this.forceActiveRow;
    }
  }

  ngAfterViewInit(): void {
    this.tableEndObserver.observe(this.spinnerElement.nativeElement);
  }

  ngOnDestroy(): void {
    this.tableEndObserver.unobserve(this.spinnerElement.nativeElement);
    this.tableEndObserver.disconnect();
  }

  /**
   * @description as style for sticky columns is based on length of the rendered date, the main purpose of this function is to optimize style processing by
   * injecting new data to the view at each scroll to the bottom
   * @param event : Scroll event
   */
  onScroll(event: Event) {
    if (!this.loadOnScroll) {
      return;
    }
    const element = event.target as HTMLElement;
    const scrollBarSize = element.offsetWidth - element.clientWidth;
    const atBottom =
      element.scrollHeight - (element.scrollTop + scrollBarSize) <=
      element.clientHeight;
    if (atBottom) {
      this.dataToView = [
        ...(this.data?.slice(0, this.loadedDataIndex + maxDataToView) ?? []),
      ];
      this.loadedDataIndex += maxDataToView;
      this.redrawHeader = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: Window }) {
    this.isMobile = event.target.innerWidth < 1200;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const parentElement = targetElement.parentElement as HTMLElement;

    if (
      this.forceActiveRow === null &&
      parentElement &&
      (!parentElement.classList.contains('active-data') ||
        !parentElement.closest('.active-data'))
    ) {
      this.activeRowIndex = -1;
    }
  }

  /**
   * @description
   * Initialize the IntersectionObserver to check if the user has scrolled to the bottom of the table
   */
  initScrollEndIntersectionObserver() {
    this.tableEndObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.scrollingSpinnerIsVisible = true;
        this.scrollingAtBottom.emit();
      }
    });
  }

  /**
   * @description
   * if the row has a validRow property, return that value
   * Else, check if the row has data for all the columns except the ones that are in the exceptPropertyValidation array
   * @param row {tablePropsInterface}
   * @returns boolean
   */
  validRowData(row: tablePropsInterface): boolean {
    return (
      row['validRow'] ??
      this.dataKeys.every(
        (dataKey) =>
          row[dataKey.key] || this.exceptPropertyValidation.includes(dataKey)
      )
    );
  }

  validCellData(dataRowElement: tablePropsType) {
    if (this.isTableMetaDataType(dataRowElement)) {
      const [, meta] = dataRowElement;
      if (meta.dataType === 'CELL_VALIDITY') {
        return meta.data;
      }
    }
    return true;
  }

  /**
   * @description
   * Add color to the icon based on the data in the row
   * @param icon {tableRowIconType}
   * @param row {tablePropsInterface}
   * @returns keyof typeof colors
   */
  setIconColor(
    icon: tableRowIconType,
    row: tablePropsInterface
  ): keyof typeof colors {
    // when setting the color of the export icon
    if (icon.name === 'export') {
      if (icon.isDisabled) {
        return classNames('neutral-300') as keyof typeof colors;
      } else {
        return classNames('success-400') as keyof typeof colors;
      }
    }

    return classNames(
      !this.validRowData(row) && icon.isPossibleToDisable
        ? 'neutral-300'
        : icon.color
    ) as keyof typeof colors;
  }

  /**
   * @description
   * Sorts the data based on the Table's header that was passed.
   * @param header {TableHeader}
   * @returns void
   */
  sortData(header: tableHeaderInterface): void {
    // reset the other headers to default sorted
    this.headers.forEach((tableHeader) => {
      if (tableHeader !== header) {
        tableHeader.sorted = 'default';
        tableHeader.isVisible = false;
      } else {
        tableHeader.sorted =
          header.sorted === 'ascending' ? 'descending' : 'ascending';
      }
    });

    this.dataCopy = this.data ? [...this.data] : [];

    const key: tableHeaderInterface['dataKey'] = header.dataKey;
    const sortingOrder = header.sorted === 'ascending' ? -1 : 1;
    this.dataToView = this.sortDataProperty(this.dataCopy, key, sortingOrder);
  }

  sortDataProperty<T, K extends keyof T>(
    array: T[],
    prop: K,
    sortOrder: 1 | -1 = 1
  ): T[] {
    return array.sort((a, b) => {
      const valueA = a[prop];
      const valueB = b[prop];
      const compareResult = (() => {
        // When having an array, the first value is the displayed value on the table and the second is the metadata
        if (
          this.isTableMetaDataType(valueA) &&
          this.isTableMetaDataType(valueB)
        ) {
          return compareMetaData[valueA[1].dataType](
            valueA[1].data,
            valueB[1].data
          );
        }

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return valueA
            .trim()
            .toLowerCase()
            .localeCompare(valueB.trim().toLowerCase());
        }
        if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
          return valueA === valueB ? 0 : valueA ? -1 : 1;
        }
        if (valueA === undefined && valueB === undefined) {
          return 0;
        }
        if (valueA === undefined || valueA === null) {
          return -1;
        }
        if (valueB === undefined || valueB === null) {
          return 1;
        }
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      })();

      return compareResult * sortOrder;
    });
  }

  /**
   * @description
   * Emit the data and index of the row to the parent component
   * @param data {tablePropsInterface}
   * @param rowIndex {number}
   * @returns void
   */
  selectRow(data: tablePropsType, rowIndex: number): void {
    rowIndex !== this.activeRowIndex
      ? (this.activeRowIndex = rowIndex)
      : (this.activeRowIndex = -1);

    this.tableRowSelection.emit({ data, rowIndex });
  }

  /**
   * @description
   * Add the hover class to the row
   * @param rowIndex {number}
   * @returns void
   */
  hoverRowEnter(rowIndex: number): void {
    if (rowIndex !== this.hoverRowIndex) {
      this.hoverRowIndex = rowIndex;
    }
  }

  isIconHeader(key: string, icon?: tableRowIconType): boolean {
    return icon?.name === key;
  }

  /**
   * @description
   * Remove the hover class from the row
   * @returns void
   */
  hoverRowLeave(): void {
    this.hoverRowIndex = -1;
  }

  hoverHeaderEnter(header: tableHeaderInterface): void {
    header.isVisible = true;
  }

  hoverHeaderLeave(header: tableHeaderInterface): void {
    if (header.sorted !== 'ascending' && header.sorted !== 'descending') {
      header.isVisible = false;
    }
  }

  /**
   * @description
   * Emit the icon name to the parent component
   * @param event {Event}
   * @param icon {tableRowIconType}
   * @returns void
   */
  iconButtonClicked(event: Event, data: tablePropsInterface): void {
    event.stopPropagation();

    this.iconEvent.emit(data);
  }

  /**
   * @description
   * Check if the element is an array
   * @param element {tablePropsType}
   * @returns boolean
   * @example
   * isArray('string') // false
   * isArray(1) // false
   * isArray([]) // true
   */
  isArray(element: tablePropsType): boolean {
    return Array.isArray(element);
  }

  fetchMetaData(data: tablePropsInterface, key: string) {
    if (this.isArray(data) && data[1] !== undefined && key in data[1]) {
      return data[1][key];
    }
    return data;
  }
  isTableMetaDataType(
    data: tablePropsType
  ): data is [string, tableMetaDataTypeInterface] {
    return (
      Array.isArray(data) &&
      data.length > 1 &&
      'dataType' in data[1] &&
      'data' in data[1] &&
      data[1].dataType in tableMetaDataType
    );
  }
}
