import { createFeatureSelector, createSelector } from '@ngrx/store';

import { vehiclesReducerInterface } from './vehicles.d';

import { vehiclesFeatureKey } from './vehicles.reducer';

export const vehiclesFeature =
  createFeatureSelector<vehiclesReducerInterface>(vehiclesFeatureKey);

export const selectVehiclesFeature = createSelector(vehiclesFeature, (data) => {
  return {
    vehiclesList: [...data.vehiclesList].sort(
      (a, b) => a.name?.localeCompare(b.name ?? '') ?? 0
    ),
    vehiclesTableData: data.vehiclesTableData,
  };
});

export const selectVehiclesCurrentIdFeature = createSelector(
  vehiclesFeature,
  (data) => data.vehiclesOpenedEntry
);
