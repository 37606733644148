import { createReducer, on } from '@ngrx/store';

import { driversReducerInterface } from './drivers';
import { driversAPIActions } from './drivers.actions';

export const driversFeatureKey = 'driversFeature';

export const driversInitialState: driversReducerInterface = {
  loaded: false,
  driversList: [],
  driversTableData: [],
  driversFormCreate: false,
  driversFormStatus: {
    hasTriedSaving: false,
    saving: false,
    errorSaving: false,
  },
  driversFormValidation: 'new',
  driversOpenedEntry: null,
  error: null,
};

export const driversFeatureReducer = createReducer(
  driversInitialState,
  on(
    driversAPIActions.driversLoaded,
    (_state, { driversList, driversTableData }) => ({
      ..._state,
      loaded: true,
      driversList,
      driversTableData,
    })
  ),
  on(driversAPIActions.driversFormCreate, (_state, { driversFormCreate }) => ({
    ..._state,
    driversFormCreate,
  })),
  on(driversAPIActions.driversFormStatus, (_state, { driversFormStatus }) => ({
    ..._state,
    driversFormStatus,
  })),
  on(
    driversAPIActions.driversAddSuccess,
    (_state, { driver, driverTable }) => ({
      ..._state,
      driversFormCreate: false,
      driversList: [..._state.driversList, driver],
      driversTableData: [..._state.driversTableData, driverTable],
      driversOpenedEntry: _state.driversTableData.length,
      error: null,
    })
  ),
  on(
    driversAPIActions.driversUpdateSuccess,
    (_state, { driver, driverTable, index }) => ({
      ..._state,
      driversList: _state.driversList.map((item, listIndex) => {
        if (item.id === driver.id) {
          index = listIndex;
          return driver;
        }
        return item;
      }),
      driversTableData: _state.driversTableData.map((item, tableIndex) =>
        index === tableIndex ? driverTable : item
      ),
      error: null,
    })
  ),
  on(driversAPIActions.driversFormValidation, (_state, { validation }) => ({
    ..._state,
    driversFormValidation: validation,
  })),
  on(driversAPIActions.driversError, (_state, { error, message }) => ({
    ..._state,
    error: {
      error,
      message,
    },
  })),
  on(driversAPIActions.driversErrorClear, (_state) => ({
    ..._state,
    error: null,
  })),
  on(
    driversAPIActions.driversSetCurrentID,
    (_state, { driversOpenedEntry }) => ({
      ..._state,
      driversOpenedEntry,
      driversFormStatus: {
        hasTriedSaving: false,
        saving: false,
        errorSaving: false,
      },
    })
  ),
  on(driversAPIActions.driversUnsetCurrentID, (_state) => ({
    ..._state,
    driversOpenedEntry: null,
  })),
  on(driversAPIActions.driverLoaded, (_state, { conflictedDriverView }) => ({
    ..._state,
    conflictedDriverView,
  }))
);
