<div class="search-bar-template-container">
  <div class="select-container">
    <ad-dropdown
      [items]="searchBarInput.dataSortInput.firstSortOptions"
      [value]="selectedSortOption.value"
      [itemsSecondList]="searchBarInput.dataSortInput.secondSortOptions"
      (dropdownElementChange)="onDateDropdownChange($event)"
    >
    </ad-dropdown>
  </div>

  <div *ngIf="isSearchBarVisible" class="search-filter-container">
    <ad-search-filter
      [sortOptions]="searchBarInput.searchFilterInput.options"
      [searchInputPlaceholder]="searchBarInput.searchFilterInput.placeholder"
      [chips]="chips"
      (dropdownChange)="onSearchBarDropDownChange($event)"
      (chipsChange)="onChipsChange($event)"
    ></ad-search-filter>
  </div>

  <div class="share-export-report-container">
    <div #export_button class="export-button-container">
      <!-- Export button for export button -->
      <button
        (click)="onExportButtonClick()"
        [disabled]="
          !searchBarData.dateSortOptionSelected ||
          !searchBarInput.exportButton.enabled
        "
        astus-design-button
        class="share-button"
        color="neutral"
        kind="outlined"
        size="sm"
      >
        {{ searchBarInput.exportInput.buttonText }}
      </button>

      <!-- button menu overlay -->
      <ng-container
        *ngTemplateOutlet="
          buttonOverlay;
          context: {
            overlaysItems: searchBarInput.exportInput.secondOverlayItems,
            secondOverlaysItems: null,
            isExportButton: true,
            overlayIsOpen: exportMenuIsOpen
          }
        "
      ></ng-container>
    </div>

    <div
      #share_button
      class="share-icon-container"
      *ngIf="searchBarInput.shareButton.visible"
    >
      <div class="icon-container">
        <ad-icon
          glyph="share"
          [color]="
            searchBarData.dateSortOptionSelected &&
            searchBarInput.shareButton.enabled
              ? 'neutral-900'
              : 'neutral-400'
          "
          class="share-icon"
          [ngClass]="{
            'share-icon--disabled':
              !searchBarData.dateSortOptionSelected ||
              !searchBarInput.shareButton.enabled
          }"
          (click)="onShareIconClick()"
        ></ad-icon>
      </div>
      <!-- button menu overlay for share icon -->
      <ng-container
        *ngTemplateOutlet="
          buttonOverlay;
          context: {
            overlaysItems: searchBarInput.shareInput.overlayItems,
            secondOverlaysItems: null,
            isExportButton: false,
            overlayIsOpen: shareMenuIsOpen
          }
        "
      ></ng-container>
    </div>
  </div>

  <!-- Template to render the overlay for share / export button -->
  <ng-template
    #buttonOverlay
    let-overlaysItems="overlaysItems"
    let-secondOverlaysItems="secondOverlaysItems"
    let-isExportButton="isExportButton"
    let-overlayIsOpen="overlayIsOpen"
  >
    <!-- button menu overlay -->
    <div
      class="overlay-menu"
      [ngClass]="{
        'overlay-menu--open': overlayIsOpen,
        'overlay-menu--close': !overlayIsOpen
      }"
    >
      <div
        class="overlay-menu-content"
        [ngClass]="{ 'share-menu-content-overlay': !isExportButton }"
      >
        <ng-container *ngFor="let item of overlaysItems; let i = index">
          <button
            class="overlay-menu-item text-p3"
            (click)="
              isExportButton
                ? onExportMenuItemClick(item)
                : onShareMenuItemClick(item)
            "
          >
            <!--If item to display in the overlay has icon we display it -->
            <ad-icon
              *ngIf="item.icon"
              [glyph]="item.icon"
              color="primary-400"
              class="overlay-menu-item-icon"
            ></ad-icon>

            <span class="clamp">{{ item.label }}</span>
          </button>
        </ng-container>

        <!-- add a divider only if template has second overlay items list -->
        <div class="divider" *ngIf="secondOverlaysItems !== null"></div>

        <ng-container *ngFor="let item of secondOverlaysItems; let i = index">
          <button
            class="overlay-menu-item text-p3"
            (click)="
              isExportButton
                ? onExportMenuItemClick(item)
                : onShareMenuItemClick(item)
            "
          >
            <!--If item to display in the overlay has icon we display it -->
            <ad-icon
              *ngIf="item.icon"
              [glyph]="item.icon"
              color="primary-400"
              class="overlay-menu-item-icon"
            ></ad-icon>

            <span class="clamp">{{ item.label }}</span>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
