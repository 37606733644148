import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import {
  DropdownItemInterface,
  ExportOutputType,
  OverlayItem,
} from '../../interfaces';

import { SearchBarOutput } from '../search-bar/search-bar';

import { SearchBarEldInput, SearchBarEldOutput } from './search-bar-eld';

@Component({
  selector: 'ad-search-bar-eld',
  templateUrl: './search-bar-eld.component.html',
  styleUrls: ['./search-bar-eld.component.scss'],
})
export class SearchBarEldTemplate implements OnChanges {
  constructor(private translateService: TranslateService) {}
  // Component Inputs
  @Input() searchBarInput: SearchBarEldInput = {
    dataSortInput: {
      firstSortOptions: [],
      secondSortOptions: [],
    },
    exportInput: {
      buttonText: '',
      overlayItems: [],
      secondOverlayItems: [],
      disableTextAlert: '',
    },
    shareInput: {
      overlayItems: [],
    },
    resourcesSelectOptions: {
      placeholder: '',
      resourcesData: [],
      isLoading: false,
    },
    typeSelectOptions: {
      typeResourcesData: [],
      placeholder: '',
    },
    shareButton: {
      visible: true,
      enabled: false,
    },
    exportButton: {
      visible: true,
      enabled: false,
    },
  };
  // Component Outputs
  @Output() searchBarOutput: EventEmitter<SearchBarEldOutput> =
    new EventEmitter();

  @Output() exportOutput = new EventEmitter<ExportOutputType>();

  searchBarData: SearchBarOutput = {
    dateSortOptionSelected: null,
    searchFilter: {
      selectedItem: null,
      selectedChipItems: [],
    },
    modalSelected: null,
  };

  searchBarEldData: SearchBarEldOutput = {
    selectedDateOptions: null,
    selectedTypeOptions: null,
    selectedResourceOptions: null,
    modalSelected: null,
  };

  // Component html elements
  @ViewChild('export_button')
  exportMenuRef!: ElementRef<HTMLDivElement>;
  @ViewChild('share_button')
  shareIconMenuRef!: ElementRef<HTMLDivElement>;

  // Component state
  selectedDateOptions: DropdownItemInterface | null = null;
  selectedTypeOptions: DropdownItemInterface | null = null;
  selectedResourceOptions: DropdownItemInterface | null = null;
  exportMenuIsOpen = false;
  shareMenuIsOpen = false;

  ngOnChanges(): void {
    if (this.searchBarInput.dataSortInput.firstSortOptions.length > 0) {
      this.selectedDateOptions =
        this.searchBarInput.dataSortInput.firstSortOptions[0];
    }
  }

  onDateItemSelection(selectedItem: DropdownItemInterface | null) {
    if (selectedItem) {
      this.selectedDateOptions = {
        ...selectedItem,
        value: selectedItem.value,
      };
      this.emitSearchBarOutput();
    }
  }

  onTypeItemSelection(selectedItem: DropdownItemInterface | null) {
    if (selectedItem) {
      this.selectedResourceOptions = null; // reset resource selection on type change
      this.selectedTypeOptions = {
        ...selectedItem,
        value: selectedItem.value,
      };
      this.emitSearchBarOutput();
    }
  }

  onResourceItemSelection(selectedItem: DropdownItemInterface | null) {
    if (selectedItem) {
      this.selectedResourceOptions = {
        ...selectedItem,
        value: selectedItem.value,
      };
      this.emitSearchBarOutput();
    }
  }

  private emitSearchBarOutput() {
    const output: SearchBarEldOutput = {
      selectedDateOptions: this.selectedDateOptions,
      selectedTypeOptions: this.selectedTypeOptions,
      selectedResourceOptions: this.selectedResourceOptions,
      modalSelected: this.searchBarEldData.modalSelected,
    };

    this.searchBarOutput.emit(output);
  }

  onExportButtonClick() {
    if (
      !this.searchBarInput.exportButton.enabled ||
      !this.selectedResourceOptions?.value
    ) {
      alert(this.translateService.instant('REPORTS.EXPORT_DISABLED'));
      return;
    }
    this.shareMenuIsOpen = false;
    this.exportMenuIsOpen = !this.exportMenuIsOpen;
  }

  onExportMenuItemClick(item: OverlayItem) {
    this.exportMenuIsOpen = false;
    this.searchBarOutput.emit({
      selectedDateOptions: this.selectedDateOptions,
      selectedTypeOptions: this.selectedTypeOptions,
      selectedResourceOptions: this.selectedResourceOptions,
      modalSelected: item,
    });
    this.exportOutput.emit({ type: item.id });
  }

  onShareIconClick() {
    this.exportMenuIsOpen = false;
    this.shareMenuIsOpen = !this.shareMenuIsOpen;
  }

  onShareMenuItemClick(item: OverlayItem) {
    this.shareMenuIsOpen = false;
    this.searchBarOutput.emit({
      selectedDateOptions: this.selectedDateOptions,
      selectedTypeOptions: this.selectedTypeOptions,
      selectedResourceOptions: this.selectedResourceOptions,
      modalSelected: item,
    });
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (
      this.exportMenuRef &&
      !this.exportMenuRef.nativeElement.contains(event.target as Node)
    ) {
      this.exportMenuIsOpen = false;
    }

    if (
      this.shareIconMenuRef &&
      !this.shareIconMenuRef.nativeElement.contains(event.target as Node)
    ) {
      this.shareMenuIsOpen = false;
    }
  }
}
