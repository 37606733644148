export enum ResourcesType {
  DRIVER = 'driver',
  VEHICLE = 'vehicle',
  ALL = 'all',
}

export function toRessourceType(resourcesType: string): ResourcesType {
  if (resourcesType === 'vehicle') {
    return ResourcesType.VEHICLE;
  }

  if (resourcesType === 'driver') {
    return ResourcesType.DRIVER;
  }

  return ResourcesType.ALL;
}

export function toBackendResourcesType(
  resourcesType: ResourcesType
): 'all' | 'driver' | 'vehicle' {
  if (resourcesType === ResourcesType.VEHICLE) {
    return 'vehicle';
  }

  if (resourcesType === ResourcesType.DRIVER) {
    return 'driver';
  }

  return 'all';
}
