import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { colors } from '../../common-ng-design-system.colors';

@Component({
  selector: 'ad-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss'],
})
export class SeparatorMolecule implements OnChanges {
  @Input() title = '';
  @Input() borderColor?: keyof typeof colors = 'neutral-100';

  @HostBinding('style.--ad-separator-border-color')
  borderColorStyle = `var(--color-${this.borderColor})`;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['borderColor']) {
      this.updateBorderColorStyle();
    }
  }

  private updateBorderColorStyle() {
    this.borderColorStyle = `var(--color-${this.borderColor})`;
  }
}
