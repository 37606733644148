import { Component, Input } from '@angular/core';

import { IconAtomGlyphType } from '../../atoms';

@Component({
  selector: 'ad-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardMolecule {
  @Input() notificationType?: string;
  @Input() glyph?: IconAtomGlyphType;
  @Input() number?: number;
  @Input() unread?: boolean;
  @Input() time?: string;
}
