import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, catchError, retry } from 'rxjs';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        let navigationExtras: NavigationExtras;
        const modelStateErrors = [];
        if (error) {
          switch (error.status) {
            // Form validation errors
            case 400:
              for (const key in error.error.errors) {
                if (error.error.errors[key]) {
                  modelStateErrors.push(error.error.errors[key]);
                }
              }
              throw modelStateErrors.flat();
            case 401:
              errorMessage = `Unauthorised ${error.status}`;
              throw errorMessage;
            case 404:
              this.router.navigateByUrl('/not-found');
              break;
            case 500:
              // eslint-disable-next-line no-case-declarations
              navigationExtras = {
                state: { error: error.error },
              };
              this.router.navigateByUrl('/server-error', navigationExtras);
              break;
            default:
              errorMessage = 'Something unexpected went wrong';
              break;
          }
        }
        throw error;
      })
    );
  }
}
