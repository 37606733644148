// Import necessary Angular modules and interfaces
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { notificationInterface } from '../store';

import { notificationTypeEnum } from './notification-center.enums';

@Component({
  selector: 'astus-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent {
  @Input() notificationData: notificationInterface[] = [];
  @Input() notificationCount = 0;
  @Output() readAllNotifications = new EventEmitter<void>();
  notificationTypeEnum = notificationTypeEnum;

  markAllAsRead(): void {
    this.readAllNotifications.emit();
  }

  trackByFn(index: number, item: notificationInterface): number | string {
    return item.id || 'index-' + index;
  }
}
