import { Injectable } from '@angular/core';
import {
  DriversService,
  DriverView,
} from '@backoffice/ng-backoffice-api-client';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { catchError, concatMap, map, of, switchMap } from 'rxjs';

import { withFleetOwnerID } from '../../../../store/global.helper';
import { resourcesAPIActions } from '../../store';

import { driversAPIActions } from './drivers.actions';
import { handleDriverDataTable } from './drivers.helpers';

@Injectable()
export class DriversEffects {
  constructor(
    private actions$: Actions,
    private driversService: DriversService,
    private translateService: TranslateService,
    private store: Store
  ) {}
  handleInfoboxSubtitle(driverInfoboxData: DriverView) {
    let subtitle = `${this.translateService.instant('INFOBOX.DRIVER')} `;
    if (driverInfoboxData.eldLicence) {
      subtitle += driverInfoboxData.eldLicence.toLocaleUpperCase();
    }
    return subtitle;
  }
  readonly getConflictedDriver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(driversAPIActions.driverLoading),
      switchMap(({ id }) =>
        withFleetOwnerID(this.store, (fleetOwnerID) =>
          this.driversService.driversControllerGetDriver(id, fleetOwnerID).pipe(
            concatMap((conflictedDriverView) => [
              {
                type: driversAPIActions.driverLoaded.type,
                conflictedDriverView: conflictedDriverView,
              },
              {
                type: resourcesAPIActions.updateInfoboxData.type,
                infoboxData: {
                  data: conflictedDriverView,
                  type: 'driver',
                },
              },
              {
                type: resourcesAPIActions.updateInfoboxHeader.type,
                infoboxHeader: {
                  title: `${conflictedDriverView.firstName} ${conflictedDriverView.lastName}`,
                  subtitle: this.handleInfoboxSubtitle(conflictedDriverView),
                  status: conflictedDriverView.validRow ? 'valid' : 'invalid',
                },
              },
              {
                type: driversAPIActions.driversSetCurrentID.type,
                driversOpenedEntry: conflictedDriverView.index,
              },
            ]),
            catchError((error) =>
              of({
                type: driversAPIActions.driversError.type,
                error: error.error,
                message: 'Error loading driver',
              })
            )
          )
        )
      )
    )
  );

  readonly getDrivers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(driversAPIActions.driversLoading),
      switchMap(() =>
        withFleetOwnerID(this.store, (fleetOwnerID) =>
          this.driversService.driversControllerFindAll(fleetOwnerID).pipe(
            map((drivers) => ({
              type: driversAPIActions.driversLoaded.type,
              driversList: drivers,
              driversTableData: drivers.map((driver) =>
                handleDriverDataTable(driver, this.translateService)
              ),
            })),
            catchError((error) =>
              of({
                type: driversAPIActions.driversError.type,
                error: error.error,
                message: 'Error loading drivers',
              })
            )
          )
        )
      )
    )
  );

  readonly addDriver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(driversAPIActions.driversAdd),
      switchMap(({ driver }) =>
        withFleetOwnerID(this.store, (fleetOwnerID) =>
          this.driversService
            .driversControllerCreateDriver(fleetOwnerID, driver)
            .pipe(
              concatMap((driverAdded) => [
                {
                  type: driversAPIActions.driversLoading.type,
                },
                {
                  type: resourcesAPIActions.resourcesCountersLoading.type,
                },
                {
                  type: driversAPIActions.driversAddSuccess.type,
                  driver: driverAdded,
                  driverTable: handleDriverDataTable(
                    driverAdded,
                    this.translateService
                  ),
                },
                {
                  type: resourcesAPIActions.updateInfoboxData.type,
                  infoboxData: {
                    type: 'driver',
                    data: driverAdded,
                  },
                },
                {
                  type: resourcesAPIActions.updateInfoboxHeader.type,
                  infoboxHeader: {
                    title: `${driver.firstName} ${driver.lastName}`,
                    subtitle: this.translateService.instant('INFOBOX.DRIVER'),
                    status: 'valid',
                  },
                },
                {
                  type: driversAPIActions.driversFormStatus.type,
                  driversFormStatus: {
                    saving: false,
                    errorSaving: false,
                    hasTriedSaving: true,
                  },
                },
              ]),
              catchError((error) => {
                return of(
                  {
                    type: driversAPIActions.driversError.type,
                    error: error.error,
                    message: 'Error adding driver',
                  },
                  {
                    type: driversAPIActions.driversFormStatus.type,
                    driversFormStatus: {
                      saving: false,
                      errorSaving: true,
                      hasTriedSaving: true,
                    },
                  }
                );
              })
            )
        )
      )
    )
  );

  readonly updateDriver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(driversAPIActions.driversUpdate),
      switchMap(({ id, driver }) =>
        withFleetOwnerID(this.store, (fleetOwnerID) =>
          this.driversService
            .driversControllerUpdateDriver(id, fleetOwnerID, driver)
            .pipe(
              concatMap((driverUpdated) => [
                {
                  type: driversAPIActions.driversLoading.type,
                },
                {
                  type: driversAPIActions.driversUpdateSuccess.type,
                  driver: driverUpdated,
                  driverTable: handleDriverDataTable(
                    driverUpdated,
                    this.translateService
                  ),
                },
                {
                  type: resourcesAPIActions.updateInfoboxHeader.type,
                  infoboxHeader: {
                    title: `${driver.firstName} ${driver.lastName}`,
                    subtitle: this.translateService.instant('INFOBOX.DRIVER'),
                    status: 'valid',
                  },
                },
                {
                  type: driversAPIActions.driversFormStatus.type,
                  driversFormStatus: {
                    saving: false,
                    errorSaving: false,
                    hasTriedSaving: true,
                  },
                },
              ]),
              catchError((error) =>
                of(
                  {
                    type: driversAPIActions.driversError.type,
                    error: error.error,
                    message: 'Error updating driver',
                  },
                  {
                    type: driversAPIActions.driversFormStatus.type,
                    driversFormStatus: {
                      saving: false,
                      errorSaving: true,
                      hasTriedSaving: true,
                    },
                  }
                )
              )
            )
        )
      )
    )
  );
}
