import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { ApiModule, BASE_PATH } from '@backoffice/ng-backoffice-api-client';
import { CommonNgDesignSystemModule } from '@common/ng-design-system';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import languaguesImport from '../assets/languages-import';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { NotFoundComponent } from './components/errors/not-found/not-found.component';
import { MenuComponent } from './components/menu/menu.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { PagesComponent } from './pages/pages.component';
import {
  ReportsDvirsEffects,
  reportsDvirsFeatureKey,
  reportsDvirsFeatureReducer,
} from './pages/reports/reports-dvir/reports-dvirs-store';
import {
  ReportsEldsEffects,
  reportsEldsFeatureKey,
  reportsEldsFeatureReducer,
} from './pages/reports/reports-eld/reports-elds-store';
import {
  DriversEffects,
  driversFeatureKey,
  driversFeatureReducer,
} from './pages/resources/drivers/drivers-store';
import {
  ResourcesEffects,
  resourcesFeatureKey,
  resourcesFeatureReducer,
} from './pages/resources/store';
import {
  UsersEffects,
  usersFeatureKey,
  usersFeatureReducer,
} from './pages/resources/users/users-store';
import {
  VehiclesEffects,
  vehiclesFeatureKey,
  vehiclesFeatureReducer,
} from './pages/resources/vehicles/vehicles-store';
import { LocalService } from './services/local-storage.service';
import { globalReducer, globalStateKey } from './store';

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, languaguesImport);
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    PagesComponent,
    MenuComponent,
    TopbarComponent,
    NotificationCenterComponent,
    UnauthorizedComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ApiModule,
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      enableTracing: false,
    }),
    CommonNgDesignSystemModule,
    AuthModule.forRoot({
      ...environment.auth0,
      httpInterceptor: {
        allowedList: [`${environment.api.serverUrl}/*`],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    StoreModule.forRoot({
      [globalStateKey]: globalReducer,
      [resourcesFeatureKey]: resourcesFeatureReducer,
      [driversFeatureKey]: driversFeatureReducer,
      [usersFeatureKey]: usersFeatureReducer,
      [vehiclesFeatureKey]: vehiclesFeatureReducer,
      [reportsDvirsFeatureKey]: reportsDvirsFeatureReducer,
      [reportsEldsFeatureKey]: reportsEldsFeatureReducer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      trace: true, // Log trace
    }),
    EffectsModule.forRoot([
      ResourcesEffects,
      DriversEffects,
      VehiclesEffects,
      UsersEffects,
      ReportsDvirsEffects,
      ReportsEldsEffects,
    ]),
  ],
  providers: [
    {
      provide: BASE_PATH,
      useValue: environment.api.serverUrl,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    LocalService,
  ],
  bootstrap: [AppComponent],
  exports: [NotificationCenterComponent, UnauthorizedComponent],
})
export class AppModule {}
