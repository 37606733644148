<div class="drawer-container">
  <div class="drawer-container__section">
    <ad-drawer-header
      *ngIf="headerTitle.length"
      [glyph]="headerGlyph"
      [title]="headerTitle"
      (iconClicked)="onIconClick()"
    ></ad-drawer-header>
    <ad-separator
      *ngIf="enableSeparator"
      [title]="separatorTitle"
    ></ad-separator>
    <div class="projected-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
