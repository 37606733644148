import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { notificationActions } from './store';
import { mockNotifications } from './store/notifications.mock';

@Component({
  selector: 'astus-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'backoffice/ng-eld';

  constructor(private translate: TranslateService, private store: Store) {
    translate.setDefaultLang('en');
    // @TODO: add the dynamism here for the lang to use
    translate.use(translate.getBrowserLang() ?? 'en');

    this.store.dispatch(
      notificationActions.getNotifications({ notifications: mockNotifications })
    );
  }
}
