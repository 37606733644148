import { Component, Input } from '@angular/core';

import { SkeletonAnimation, SkeletonShape } from './skeleton.enum';

@Component({
  selector: 'ad-skeleton',
  template: `<div
    adSkeleton
    [adSkeletonAnimation]="animation"
    [adSkeletonWidth]="width"
    [adSkeletonHeight]="height"
    [adSkeletonShape]="shape"
  >
    &nbsp;
  </div> `,
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonMolecule {
  @Input() height = '100%';
  @Input() width = '100%';
  @Input() shape: SkeletonShape = 'rectangle';
  @Input() animation: SkeletonAnimation = 'pulse';
}
