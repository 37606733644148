<label
  class="switch-container"
  [ngClass]="{ 'switch-container--disabled': disabled }"
>
  <div class="switch-wrapper">
    <input
      type="checkbox"
      class="hide"
      [disabled]="disabled"
      [checked]="checked"
      (change)="onToggle()"
    />
    <div [ngClass]="buttonContainerClasses" class="button-container">
      <div [ngClass]="iconContainerClasses" class="icon-container">
        <ad-icon
          *ngIf="icon"
          [glyph]="iconGlyph"
          [color]="disabled ? 'neutral-200' : 'neutral-900'"
        >
        </ad-icon>
      </div>
    </div>
  </div>
</label>
