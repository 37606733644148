import { Component, Input, Output, EventEmitter } from '@angular/core';

import { IconAtomType } from '../../atoms';

@Component({
  selector: 'ad-drawer',
  templateUrl: './navigation-drawer.component.html',
  styleUrls: ['./navigation-drawer.component.scss'],
})
export class NavigationDrawerOrganism {
  @Input() headerGlyph: Extract<IconAtomType, 'close' | 'chevron_left'> =
    'close';

  @Input() headerTitle = '';

  @Input() enableSeparator = false;

  @Input() separatorTitle = '';

  @Output() headerIconClicked = new EventEmitter<boolean>();

  onIconClick = () => {
    this.headerIconClicked.emit();
  };
}
