import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { ChipItem } from '../../interfaces/chip-item';
import { DropdownItemInterface } from '../dropdown/dropdown';

@Component({
  selector: 'ad-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterOrganism
  implements AfterViewInit, AfterViewChecked, OnInit
{
  @Input() sortOptions: DropdownItemInterface[] = [];

  @Input() chips: ChipItem[] = [];

  @Input() searchInputPlaceholder = '';

  @Output()
  dropdownChange: EventEmitter<DropdownItemInterface> = new EventEmitter();

  @Output()
  chipsChange: EventEmitter<ChipItem[]> = new EventEmitter();

  @ViewChild('searchInput')
  searchInput!: ElementRef<HTMLInputElement>;

  @ViewChild('chipsInput', { read: ElementRef<HTMLDivElement> })
  chipsInput!: ElementRef<HTMLDivElement>;

  chipIterator = 0;

  userHasUpdateChipsList = false;

  dropdownValue: DropdownItemInterface = {
    value: '',
    label: '',
  };

  ngOnInit(): void {
    this.dropdownValue = this.sortOptions[0];
  }

  ngAfterViewInit(): void {
    // scroll by default to the chip list right end
    // at the component life start
    this.scrollToEndChipsList();
    this.chipIterator = this.chips.length;
  }

  ngAfterViewChecked() {
    // scroll only if needed
    // if chips is updated by users
    if (this.userHasUpdateChipsList) {
      this.scrollToEndChipsList();
      this.userHasUpdateChipsList = false;
    }
  }

  /**
   * This method is handled the click on the chip close button.
   * @param {number} chipId The chip id.
   */
  onChipCloseClick(chipId: number) {
    this.chips = this.chips.filter((chip) => chip.id !== chipId);
    this.chipsChange.emit(this.chips);
  }

  /**
   * This method is handled when user press on `enter` or `tab` key inside the `search field`.
   * @param {KeyboardEvent} event The keyboard event.
   */
  onKeyPress(event: KeyboardEvent | Event) {
    const { value } = this.searchInput.nativeElement;

    // handle empty search value
    if (value.length > 0 && value.trim() !== '') {
      event.preventDefault();

      // update chips list + searchFilter
      this.chips = [...this.chips, { id: this.chipIterator, value }];

      this.dropdownChange.emit(this.dropdownValue);
      this.chipsChange.emit(this.chips);
      this.searchInput.nativeElement.value = '';
      this.userHasUpdateChipsList = true;
      this.chipIterator += 1;
    }
  }

  /**
   * This method is handled when user select a value from the dropdown.
   * @param {DropdownItemInterface} dropdownValue The dropdown value.
   **/
  onDropdownValueChange(dropdownValue: DropdownItemInterface | null) {
    if (dropdownValue) {
      this.dropdownValue = dropdownValue;
      this.dropdownChange.emit(dropdownValue);
    }
  }

  /**
   * This method can scroll the chip list to the right end.
   **/
  scrollToEndChipsList() {
    if (this.chipsInput) {
      this.chipsInput.nativeElement.scrollLeft =
        this.chipsInput.nativeElement.scrollWidth;
    }
  }
}
