export class AssertionError extends Error {}

export function assertNever(x: never): never {
  // This is ok as we should never reach this spot. If we do at runtime, we want to know the value of whatever
  // ended up here.
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw new AssertionError(`Unexpected object: ${x}`);
}
export function assertNotNullOrUndefined<T>(
  value: T | null | undefined,
  message = 'Assert fail, value should not be null or undefined'
): asserts value is NonNullable<T> {
  /**
   * == null is the equivalent of `x === null || x === undefined`
   */
  if (value == null) {
    throw new AssertionError(message);
  }
}

export function assert(
  condition: boolean,
  message = 'Assert fail'
): asserts condition {
  if (!condition) {
    throw new AssertionError(message);
  }
}

/**
 * Lets us quickly throw an error when doing an `or undefined` check
 *
 * You can simply return `value ?? raise("value was undefined")` and it will throw an error if the value is undefined,
 * whilst removing "undefined" from the type.
 *
 * @see https://www.youtube.com/channel/UCswG6FSbgZjbWtdf_hMLaow/community?lb=Ugkx6PsS8v6CMbRPnt020qV6k2gzhyEHhgdU
 */
export function raise(message: string): never {
  throw new AssertionError(message);
}
