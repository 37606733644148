import { createFeatureSelector, createSelector } from '@ngrx/store';

import { eldStoreInterface } from './reports-elds';
import { reportsEldsFeatureKey } from './reports-elds.reducers';

export const reportsFeature = createFeatureSelector<eldStoreInterface>(
  reportsEldsFeatureKey
);

export const selectStoreEldReports = createSelector(
  reportsFeature,
  (data) => data
);

export const selectStoreEldSearchBar = createSelector(
  reportsFeature,
  (data) => data.searchBar
);

export const selectStoreEldPagination = createSelector(
  reportsFeature,
  (data) => data.pagination
);
