<label class="input-main">
  <span *ngIf="label.length > 0" class="text-caption--semibold">{{
    label + (required ? ' *' : '')
  }}</span>
  <textarea
    *ngIf="type === 'textarea'; else input"
    class="text-p3"
    [disabled]="disabled"
    [name]="name"
    [placeholder]="placeholder"
    (input)="onInputChange($event)"
    [attr.maxlength]="maxLength"
    [(ngModel)]="value"
    >{{ value }}</textarea
  >
  <ng-template #input>
    <div
      class="input-container"
      [ngClass]="{
        'input-container--has-leading-content': leadingContent.children.length,
        'input-container--has-trailing-content':
          trailingContent.children.length || copyToClipboard
      }"
    >
      <div
        class="icon-container"
        [ngClass]="{
          'icon-container--has-content': leadingContent.children.length
        }"
        #leadingContent
      >
        <ng-content select="[leading-content]"></ng-content>
      </div>
      <input
        [type]="type"
        [value]="value"
        (input)="onInputChange($event)"
        [disabled]="disabled"
        [name]="name"
        [placeholder]="placeholder"
        [class]="isMobile ? 'text-p2' : 'text-p3'"
      />
      <div
        class="icon-container"
        [ngClass]="{
          'icon-container--has-content':
            trailingContent.children.length || copyToClipboard
        }"
        #trailingContent
      >
        <ng-content select="[trailing-content]"></ng-content>

        <button
          *ngIf="copyToClipboard"
          class="icon-container__copy-to-clipboard"
          (click)="onCopyToClipboard()"
          type="button"
        >
          <ad-icon glyph="copy-filled" color="primary-400"></ad-icon>
        </button>
      </div>
    </div>
  </ng-template>
</label>
<div
  *ngIf="errorMessage && objectKeys(errorMessage).length > 0"
  class="error-message"
  [ngClass]="{ 'error-message--show': errors }"
>
  <div class="error-message__inner">
    <div
      *ngFor="let error of errorMessage | keyvalue"
      class="error-message__item"
      [ngClass]="{
        'error-message__item--show': errors?.hasOwnProperty(error.key)
      }"
    >
      <span class="error-message__item-inner text-caption">
        {{ error.value }}
      </span>
    </div>
  </div>
</div>
