import { createReducer, on } from '@ngrx/store';

import { ResourcesType } from '../../constants/resource';

import { TimeType } from '../../constants/time';

import { ReportsDvirsInterface } from './reports-dvirs';
import { reportsDvirsAPIActions } from './reports-dvirs.actions';

export const reportsDvirsFeatureKey = 'reportsDvirsFeature';

export const reportsDvirsInitialState: ReportsDvirsInterface = {
  loaded: false,
  dvirs: [],
  error: null,
  pagination: {
    currentPage: 1,
    totalPage: 1,
  },
  searchBar: {
    date: TimeType.TODAY,
    resourcesType: ResourcesType.ALL,
    resourcesNameElements: [],
  },
};

export const reportsDvirsFeatureReducer = createReducer<ReportsDvirsInterface>(
  reportsDvirsInitialState,
  on(reportsDvirsAPIActions.dvirsReportsLoading, (_state) => ({
    ..._state,
    dvirs: reportsDvirsInitialState.dvirs,
    pagination: reportsDvirsInitialState.pagination,
  })),

  on(reportsDvirsAPIActions.dvirsReportsLoaded, (_, { reportsDvirs }) => ({
    ...reportsDvirs,
  })),

  on(reportsDvirsAPIActions.dvirsReportsError, (_state, { error }) => ({
    ..._state,
    dvir: {
      ..._state.dvirs,
      error,
      loaded: false,
    },
    pagination: {
      ..._state.pagination,
    },
  })),

  on(reportsDvirsAPIActions.dvirsSearchBarUpdate, (_state, { searchBar }) => ({
    ..._state,
    loaded: false, // set to false because we are loading new data
    pagination: reportsDvirsInitialState.pagination, // set to default pagination
    dvirs: reportsDvirsInitialState.dvirs, // set to default dvirs
    searchBar: {
      ...searchBar,
    },
  }))
);
