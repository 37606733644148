export function convertToPhoneNumber(phoneNotFormated: string) {
  const digits = phoneNotFormated.replace(/\D/g, '');

  return `(${digits.substring(0, 3)}) ${digits.substring(
    3,
    6
  )}-${digits.substring(6, 10)}`;
}

export function convertPhoneToString(phoneNumber: string) {
  const digits = phoneNumber.replace(/\D/g, '');

  return `${digits.substring(0, 3)}${digits.substring(3, 6)}${digits.substring(
    6,
    10
  )}`;
}
