import { createReducer, on } from '@ngrx/store';

import { TimeType } from '../../constants/time';

import { eldStoreInterface } from './reports-elds';
import { reportsEldsAPIActions } from './reports-elds-actions';

export const reportsEldsFeatureKey = 'reportsEldsFeatureKey';

export const reportsEldInitialState: eldStoreInterface = {
  loaded: true,
  elds: [],
  error: null,
  pagination: {
    currentPage: 1,
    totalPage: 1,
  },
  searchBar: {
    date: TimeType.TODAY,
    resourcesType: null,
    resourceId: null,
  },
};

export const reportsEldsFeatureReducer = createReducer<eldStoreInterface>(
  reportsEldInitialState,
  on(reportsEldsAPIActions.eldReportsLoading, (_state) => ({
    ..._state,
    elds: reportsEldInitialState.elds,
  })),
  on(reportsEldsAPIActions.eldReportsLoaded, (_state, { eld }) => ({
    ..._state,
    ...eld,
  })),

  on(reportsEldsAPIActions.eldReportsError, (_state, { error }) => ({
    ..._state,
    eld: {
      ..._state,
      error,
      loaded: false,
    },
  })),

  on(reportsEldsAPIActions.eldSearchBarUpdate, (_state, { searchBar }) => ({
    ..._state,
    searchBar,
    pagination: reportsEldInitialState.pagination,
    loaded: false,
  }))
);
