import type { Environment } from './environment.type';

export const environment: Environment = {
  production: true,
  name: 'staging',
  auth0: {
    domain: 'auth.preprod.astus.cloud',
    clientId: 'Lx6oRBiY0IDtEa2PwztkJWzYr5HRs63C',
    authorizationParams: {
      // eslint-disable-next-line camelcase
      redirect_uri: window.location.origin,
      audience: 'https://api.astus.com',
    },
  },
  api: {
    serverUrl: 'https://api.staging.backoffice.astus.cloud:3001',
  },
  launchDarkly: {
    clientSideId: '6643894d6edd721020476e1a',
  },
};
