import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';

import { environment } from '../../../environments/environment';
import {
  getNotifications,
  menuActions,
  notificationActions,
  notificationInterface,
  showMenuFeature,
} from '../../store';

@Component({
  selector: 'astus-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Output() showMenuChange = new EventEmitter<boolean>();

  @Input() showMenu = false;
  showNotificationCenter = false;
  notifications: notificationInterface[] = [];
  notificationCount = 0;

  shouldDisplayAstusWeb = environment.name !== 'staging';

  constructor(
    private auth: AuthService,
    @Inject(DOCUMENT) private doc: Document,
    private store: Store
  ) {
    this.store.select(showMenuFeature).subscribe((showMenu) => {
      this.showMenu = showMenu;
      if (!showMenu && this.showNotificationCenter) this.readAllNotifications();
      if (!showMenu) this.showNotificationCenter = false;
    });
    this.store.select(getNotifications).subscribe((notifications) => {
      this.notifications = notifications;
      this.notificationCount = notifications.filter((n) => n.unread).length;
    });
  }

  closeMenu() {
    this.store.dispatch(menuActions.closeMenu());
  }

  toggleNotificationCenter() {
    this.showNotificationCenter = !this.showNotificationCenter;
  }

  readAllNotifications() {
    this.store.dispatch(notificationActions.markAllAsRead());
  }

  handleLogout(): void {
    this.closeMenu();
    this.auth.logout({
      logoutParams: {
        returnTo: this.doc.location.origin,
      },
    });
  }
}
