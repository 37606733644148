import { TimeType } from '../pages/reports/constants/time';

export function calculateDate(timeType: TimeType): Date | null {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // we always want to include the whole day

  if (timeType === TimeType.YESTERDAY) {
    currentDate.setDate(currentDate.getDate() - 1);
  }

  if (timeType === TimeType.LAST_SEVEN_DAYS) {
    currentDate.setDate(currentDate.getDate() - 7);
  }

  if (timeType === TimeType.LAST_FOURTEEN_DAYS) {
    currentDate.setDate(currentDate.getDate() - 14);
  }

  if (timeType === TimeType.LAST_THIRTY_DAYS) {
    currentDate.setDate(currentDate.getDate() - 30);
  }

  if (timeType === TimeType.LAST_MONTH) {
    currentDate.setDate(1);
  }

  if (timeType === TimeType.LAST_SIX_MONTHS) {
    currentDate.setMonth(currentDate.getMonth() - 6);
  }

  if (timeType === TimeType.LAST_YEAR) {
    currentDate.setFullYear(currentDate.getFullYear() - 1);
  }

  if (timeType === TimeType.THIS_YEAR) {
    currentDate.setMonth(0); // Set to January of the current year
    currentDate.setDate(1); // Set the day of the month to 1
  }

  if (timeType === TimeType.LAST_TWO_YEARS) {
    currentDate.setFullYear(currentDate.getFullYear() - 2);
  }
  // TODO: Handle the custom period. For now we dont have it
  return currentDate;
}
