import { createFeatureSelector, createSelector } from '@ngrx/store';

import { driversReducerInterface } from './drivers';
import { driversFeatureKey } from './drivers.reducer';

export const driversFeature =
  createFeatureSelector<driversReducerInterface>(driversFeatureKey);

export const selectDriversFeature = createSelector(driversFeature, (data) => {
  return {
    driversList: data.driversList,
    driversTableData: data.driversTableData,
    driversOpenedEntry: data.driversOpenedEntry,
  };
});

export const selectDriversCurrentIdFeature = createSelector(
  driversFeature,
  (data) => data.driversOpenedEntry
);

export const selectDriverEditedFeature = createSelector(
  driversFeature,
  (data) => data.driverEditedName
);

export const selectDriversFormFeature = createSelector(
  driversFeature,
  (data) => data.driversFormStatus
);

export const selectDriversFormCreateFeature = createSelector(
  driversFeature,
  (data) => data.driversFormCreate
);

export const selectDriversFormErrorFeature = createSelector(
  driversFeature,
  (data) => data.error
);
