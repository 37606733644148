import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';

import {
  ChipItem,
  DropdownItemInterface,
  ExportOutputType,
  OverlayItem,
} from '../../interfaces';

import { SearchBarInput, SearchBarOutput } from './search-bar';

@Component({
  selector: 'ad-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarTemplate implements OnChanges {
  // @TODO remove this property once we can reimplemment the search bar for BOF
  @Input()
  isSearchBarVisible = true;
  // Component Inputs
  @Input() searchBarInput: SearchBarInput = {
    dataSortInput: {
      firstSortOptions: [],
      secondSortOptions: [],
    },
    searchFilterInput: {
      options: [],
      placeholder: '',
    },
    exportInput: { buttonText: '', overlayItems: [], secondOverlayItems: [] },
    shareInput: {
      overlayItems: [],
    },
    shareButton: {
      visible: false,
      enabled: false,
    },
    exportButton: {
      visible: true,
      enabled: false,
    },
  };

  // Component Outputs
  @Output() searchBarDataChange = new EventEmitter<SearchBarOutput>();
  @Output() exportOutput = new EventEmitter<ExportOutputType>();

  // Component data source
  chips: Array<ChipItem> = [];

  // Component state
  selectedSortOption!: DropdownItemInterface;
  exportMenuIsOpen = false;
  shareMenuIsOpen = false;

  searchBarData: SearchBarOutput = {
    dateSortOptionSelected: null,
    searchFilter: {
      selectedItem: null,
      selectedChipItems: [],
    },
    modalSelected: null,
  };

  // Component html elements
  @ViewChild('export_button')
  exportMenuRef!: ElementRef<HTMLDivElement>;
  @ViewChild('share_button')
  shareIconMenuRef!: ElementRef<HTMLDivElement>;

  ngOnChanges(): void {
    if (this.searchBarInput.dataSortInput.firstSortOptions.length) {
      this.selectedSortOption =
        this.searchBarInput.dataSortInput.firstSortOptions[0];
    }
  }

  onExportButtonClick() {
    // close share menu overlay before open the export menu
    this.shareMenuIsOpen = false;
    this.exportMenuIsOpen = !this.exportMenuIsOpen;
    // TODO implement export button click
    // should be open the modal
  }

  onExportMenuItemClick(item: OverlayItem) {
    // close the export menu
    this.exportMenuIsOpen = false;
    this.searchBarDataChange.emit({
      ...this.searchBarData,
      modalSelected: item,
    });
    this.exportOutput.emit({ type: item.id, fileName: 'dvir_reports.pdf' });
  }

  onShareIconClick() {
    // close export menu overlay before open the share menu
    this.exportMenuIsOpen = false;
    this.shareMenuIsOpen = !this.shareMenuIsOpen;
  }

  onShareMenuItemClick(item: OverlayItem) {
    // close the share menu
    this.shareMenuIsOpen = false;
    // TODO implement share button click
    // should be open the modal for data transfer / error datatransfer
    this.searchBarDataChange.emit({
      ...this.searchBarData,
      modalSelected: item,
    });
  }

  onSearchBarDropDownChange(filter: DropdownItemInterface) {
    this.searchBarData = {
      ...this.searchBarData,
      searchFilter: {
        selectedItem: { ...filter },
        selectedChipItems: [
          ...this.searchBarData.searchFilter.selectedChipItems,
        ],
      },
    };

    this.searchBarDataChange.emit(this.searchBarData);
  }

  onDateDropdownChange(option: DropdownItemInterface | null) {
    if (!option) {
      return;
    }

    this.searchBarData = {
      ...this.searchBarData,
      dateSortOptionSelected: option,
    };
    this.searchBarDataChange.emit(this.searchBarData);
  }

  onChipsChange(chips: Array<ChipItem>) {
    this.searchBarData = {
      ...this.searchBarData,
      searchFilter: {
        selectedItem: this.searchBarData.searchFilter.selectedItem,
        selectedChipItems: chips,
      },
    };

    this.searchBarDataChange.emit(this.searchBarData);
  }

  onSelectChange(option: DropdownItemInterface) {
    this.searchBarData = {
      ...this.searchBarData,
      dateSortOptionSelected: option,
    };
    this.searchBarDataChange.emit(this.searchBarData);
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (
      this.exportMenuRef &&
      !this.exportMenuRef.nativeElement.contains(event.target as Node)
    ) {
      this.exportMenuIsOpen = false;
    }

    if (
      this.shareIconMenuRef &&
      !this.shareIconMenuRef.nativeElement.contains(event.target as Node)
    ) {
      this.shareMenuIsOpen = false;
    }
  }
}
