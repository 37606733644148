import { createActionGroup, props, emptyProps } from '@ngrx/store';

import { notificationInterface } from './global';

export const menuActions = createActionGroup({
  source: 'Menu',
  events: {
    'Close menu': emptyProps(),
    'Toggle menu': props<{ showMenu: boolean }>(),
  },
});

export const notificationActions = createActionGroup({
  source: 'Notification',
  events: {
    'Mark all as read': emptyProps(),
    'Get notifications': props<{ notifications: notificationInterface[] }>(),
  },
});

export const fleetOwnerActions = createActionGroup({
  source: 'Fleet Owner',
  events: {
    'Fleet Owner ID loaded': props<{ fleetOwnerID: number }>(),
  },
});
