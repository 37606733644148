<div
  class="title-section"
  [ngClass]="{
    'text-padding': title,
    'border-padding': !title
  }"
>
  <div
    *ngIf="title.length > 0; else borderBlock"
    class="title-section__text text-p2"
  >
    {{ title }}
  </div>

  <ng-template #borderBlock
    ><div class="title-section__border"></div
  ></ng-template>
</div>
