export default [
  { prefix: './assets/i18n/', suffix: '.json' },
  { prefix: './assets/i18n/not-found/', suffix: '.json' },
  { prefix: './assets/i18n/pages/', suffix: '.json' },
  { prefix: './assets/i18n/menu-topbar/', suffix: '.json' },
  { prefix: './assets/i18n/notification-center/', suffix: '.json' },
  { prefix: './assets/i18n/infobox/', suffix: '.json' },
  { prefix: './assets/i18n/vehicle-types/', suffix: '.json' },
  { prefix: './assets/i18n/table/', suffix: '.json' },
  { prefix: './assets/i18n/reporting-tools/', suffix: '.json' },
  { prefix: './assets/i18n/error/', suffix: '.json' },
  { prefix: './assets/i18n/unauthorized/', suffix: '.json' },
];
