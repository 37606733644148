import { Component, Input } from '@angular/core';

@Component({
  selector: 'ad-drawer-panel',
  templateUrl: './navigation-drawer-panel.component.html',
  styleUrls: ['./navigation-drawer-panel.component.scss'],
})
export class NavigationDrawerPanelMolecule {
  @Input() isOpen = false;
  @Input() navOpen = false;
}
